import React, { Fragment } from 'react'
import styled from 'styled-components'
import Switch from 'react-switch'

import { DataPoint, ElementType } from '../constants/reporting'
import { computeElementForDatapoint } from '../containers/dashboard/data-parsing'

import Chart from './Chart'
import ChartType from '../constants/charting'

import { FigureTitle, FigureSubtitle, TitleDetail } from './Text'
import Icon, { IconName } from './Icon'
import Color from './Color'

import { Pane, PaneRow, PaneHeight } from './Pane'
import { Desktop, Media } from './MediaQuery'

const GenderIcon = styled.div`
  fill: ${Color.Primary.Main};
  width: 100%;
  height: 100%;
  ${Media.Tablet`
    display: none;
  `}
`

const DeviceIcon = styled(GenderIcon)`
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
`

const GenderFigures = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.Danger.Main};
  ${Media.Tablet`
    flex-direction: column;
  `};
`

const DeviceFigures = styled(GenderFigures)`
  flex-direction: column;
  align-items: flex-start;
`

const GenderFigure = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: ${props => (props.first ? '4rem' : '0')}; */
  margin-right: 3rem;
  margin-left: ${props => (props.first ? '3rem' : '0')};
  ${Media.Tablet`
    margin: 1rem 0;
  `};
`

const DeviceFigure = styled(GenderFigure)`
  margin: 1rem 0;
`

const GenderText = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

const GenderTitle = styled(FigureTitle)`
  margin: 0;
`

const GenderSubtitle = styled(FigureSubtitle)`
  margin: 0;
  text-transform: capitalize;
  color: ${Color.Black.Light};
`

const DeviceTitle = styled(GenderTitle)`
  font-size: 2.2rem;
`

const UserAgeGenderSplitRow = ({ dataset, isLoading, print }) => {
  const genderSplit = computeElementForDatapoint({
    dataset,
    elementType: ElementType.Proportion,
    dataPoint: DataPoint.UserGenderSplit,
  })
  const devicesSplit = computeElementForDatapoint({
    dataset,
    elementType: ElementType.Proportion,
    dataPoint: DataPoint.UserDevicesSplit,
  })
  //
  const genderPane = (
    <Pane
      icon
      height={PaneHeight.Medium}
      flex={1.5}
      position="first"
      elementType={ElementType.Proportion}
      direction="horizontal"
      title="Gender Split"
      tooltip="Demographic data is gathered by Google, based on the people that are logged into a<br>Google profile (e.g. Google Chrome, Gmail or YouTube) while viewing the article."
      value={
        <GenderFigures>
          {genderSplit.map(({ name, value }, index) => (
            <GenderFigure first={index === 0} key={`gender-figure-${name}`}>
              <GenderIcon>
                <Icon name={IconName[name]} />
              </GenderIcon>
              <GenderText>
                <GenderTitle>{value}%</GenderTitle>
                <GenderSubtitle>{name} Users</GenderSubtitle>
              </GenderText>
            </GenderFigure>
          ))}
        </GenderFigures>
      }
      isLoading={isLoading}
    />
  )

  const devicesPane = (
    <Pane
      icon
      height={PaneHeight.Medium}
      flex={1}
      position="last"
      elementType={ElementType.Proportion}
      direction="vertical"
      title="Device Split"
      tooltip="The share of Laptop / Tablet / Mobile visitors."
      value={
        <DeviceFigures>
          {devicesSplit.map(({ name, value }) => (
            <DeviceFigure key={`device-figure-${name}`}>
              <DeviceIcon>
                <Icon name={IconName[name]} />
              </DeviceIcon>
              <GenderText>
                <DeviceTitle>{value}%</DeviceTitle>
                <GenderSubtitle>{name} Users</GenderSubtitle>
              </GenderText>
            </DeviceFigure>
          ))}
        </DeviceFigures>
      }
      isLoading={isLoading}
    />
  )

  return (
    <Desktop>
      {isDesktop =>
        isDesktop && !print ? (
          <PaneRow>
            {genderPane}
            {devicesPane}
          </PaneRow>
        ) : (
          <Fragment>
            <PaneRow>{genderPane}</PaneRow>
            <PaneRow>{devicesPane}</PaneRow>
          </Fragment>
        )
      }
    </Desktop>
  )
}

const PageViewsChart = ({ data, isLoading, showPageViewsAsCumulative, checkCumulative, tooltip }) => {
  const displayChartAsFigure = data.points.length <= 1
  return (
    <PaneRow>
      <Pane
        height={PaneHeight.Medium}
        isLoading={isLoading}
        elementType={displayChartAsFigure ? ElementType.Figure : ElementType.Series}
        info={displayChartAsFigure}
        title="Daily Views"
        tooltip="Page views per day. A page view is tracked every time someone loads the article page."
        titleRight={
          <Fragment>
            <TitleDetail marginRight>Cumulative:&nbsp;</TitleDetail>
            <Switch
              disabled={isLoading}
              onChange={checkCumulative}
              checked={showPageViewsAsCumulative}
              onColor={Color.Primary.Light}
              onHandleColor={Color.Primary.Main}
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={14}
              width={30}
            />
          </Fragment>
        }
        value={
          displayChartAsFigure ? (
            'Cannot display chart for single day'
          ) : (
            <Chart
              series={showPageViewsAsCumulative ? data.pointsCumulative : data.points}
              type={ChartType.LineDates}
            />
          )
        }
      />
    </PaneRow>
  )
}

export { UserAgeGenderSplitRow, PageViewsChart }
