import styled from 'styled-components'
import * as SIZES from '../constants/sizes'

const PageContainer = styled.div`
  background-color: ${props => props.color || '#F4F4F4'};
  width: 100vw;
  height: ${props => (props.fullheight ? '100vh' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => (props.col ? 'column' : 'row')};
  position: relative;

  transition: background-color 0.4s;
`

const Wrap = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${SIZES.MAX_WIDTH};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: ${props => (props.col ? 'column' : 'row')};
`

const PageBreak = styled.div`
  display: none;
  @media print {
    display: block;
    page-break-after: always;
  }
`

const HideFromPrint = styled.div`
  @media print {
    display: none;
  }
`

export { PageContainer, Wrap, PageBreak, HideFromPrint }
