import React, { Component } from 'react'

import { PageBreak } from '../../../components/Layout'

import { computeElementForDatapoint } from '../data-parsing'
import { DataPoint, ElementType } from '../../../constants/reporting'

import { Pane, PaneRow, PaneHeight } from '../../../components/Pane'
import { UserAgeGenderSplitRow, PageViewsChart } from '../../../components/FormattedPane'

class Overview extends Component {
  state = {
    showPageViewsAsCumulative: false,
  }

  render = () => {
    const { dataset, isLoading, print } = this.props
    const { showPageViewsAsCumulative } = this.state
    const usersTotal = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Figure,
      dataPoint: DataPoint.UserTotal,
    })
    const averageTimeOnPage = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Figure,
      dataPoint: DataPoint.PageAverageTime,
    })
    // const videoPlaysTotal = computeElementForDatapoint({
    //   dataset,
    //   elementType: ElementType.Figure,
    //   dataPoint: DataPoint.VideoPlays,
    // })
    const pageViewsSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.PageViews,
    })

    if (!dataset) {
      return <div>No data available</div>
    }

    return (
      <div>
        <PaneRow>
          <Pane
            fill
            height={PaneHeight.Short}
            position="last"
            elementType={ElementType.Figure}
            title="Ad Impressions"
            tooltip="The number of times the article excerpt was viewed on TV2.no.<br>This number is not unique, so one person might be accountable for multiple of these impressions."
            value={dataset.AdManager.impressions.toLocaleString()}
            isLoading={isLoading}
          />
          <Pane
            fill
            height={PaneHeight.Short}
            position="last"
            elementType={ElementType.Figure}
            title="Ad Click-through Rate"
            tooltip="The click-through rate (CTR) reflects how many % of impressions turned into a click to the article page.<br>Typically, TV2’s click-through rate for native ads range from 0.1%-0.5%"
            value={`${(dataset.AdManager.clickThroughRate * 100).toFixed(2)}%`}
            isLoading={isLoading}
          />
          <Pane
            fill
            height={PaneHeight.Short}
            position="first"
            elementType={ElementType.Figure}
            title="Total Unique Users"
            tooltip="The number of unique browsers visiting the article.<br>A person may visit the article from both their laptop and phone - in which case we would count 2 unique users."
            value={usersTotal}
            isLoading={isLoading}
          />
          <Pane
            fill
            height={PaneHeight.Short}
            position="inner"
            elementType={ElementType.Figure}
            title="Avg. Time on Page"
            tooltip="The average time spent on the article."
            value={averageTimeOnPage}
            isLoading={isLoading}
          />
        </PaneRow>
        <PageBreak />
        <UserAgeGenderSplitRow dataset={dataset} isLoading={isLoading} print={print} />
        <PageBreak />
        <PageViewsChart
          data={pageViewsSeries}
          isLoading={isLoading}
          showPageViewsAsCumulative={showPageViewsAsCumulative}
          checkCumulative={checked => this.setState({ showPageViewsAsCumulative: checked })}
        />
      </div>
    )
  }
}

export default Overview
