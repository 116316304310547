import React from 'react'
import styled from 'styled-components'
import { FlexibleXYPlot, HorizontalBarSeries } from 'react-vis'
import numeral from 'numeral'

import Color from '../Color'
import { TitleDetail } from '../Text'

const SplitContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  /* background-color: #ddd; */
  position: relative;
`

const SplitStack = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const StackTitle = styled(TitleDetail)`
  margin: 0;
  align-self: ${props => props.align};
  height: 2rem;
`

const CenterStack = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

const StackLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`

const OverlayStack = styled(CenterStack)`
  width: 100%;
  height: 100%;
  position: absolute;
  /* background-color: yellow;
  opacity: 0.5; */
  top: 0;
  left: 0;
  pointer-events: none;
`

const StackLabel = styled(TitleDetail)`
  margin: 0;
  /* height: 70px; */
`

const SplitLabelStack = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const SplitLabel = styled(StackLabels)`
  flex: 2;
  align-items: ${props => (props.invert ? 'flex-end' : 'flex-start')};
`

const SplitLabelPart = styled(TitleDetail)`
  display: flex;
  margin: 0;
  padding: 0.2rem 0.2rem;
  /* border: 2px solid ${Color.Danger.Light}; */
  opacity: ${props => (props.show ? 1 : 0)};
  color: white;
  font-size: 1.4rem;
`

const StackBars = ({ inverted, series, resetCrosshairs, updateCrosshairs, crosshairs }) => (
  <FlexibleXYPlot
    onMouseLeave={resetCrosshairs}
    color={Color.Primary.Main}
    animation="no-wobble"
    // xDomain={[inverted ? -100 : 0, inverted ? 0 : 100]}
    margin={{
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }}>
    {series && (
      <HorizontalBarSeries
        // style={{ strokeWidth: ".6em" }}
        onValueMouseOver={updateCrosshairs}
        onValueMouseOut={resetCrosshairs}
        color={Color.Primary.Light}
        key={1}
        colorType="literal"
        data={series.map(({ x, y }) => ({
          x: inverted ? -x : x,
          x0: 0,
          y,
          color: crosshairs.length
            ? crosshairs[0].y === y
              ? Color.Danger.Main
              : Color.Primary.Light
            : Color.Primary.Light,
        }))}
      />
    )}
    {/* <Crosshair
      style={{
        line: { background: Color.Danger.Main, width: "2px" },
        title: { color: "#444" }
      }}
      values={crosshairs}
    >
      <h1> {numeral(crosshairs.length && crosshairs[0].x).format("0,0")}</h1>
    </Crosshair> */}
  </FlexibleXYPlot>
)

const SplitBars = ({
  leftSeries,
  rightSeries,
  leftTitle,
  rightTitle,
  labels,
  resetCrosshairs,
  updateCrosshairs,
  crosshairs,
}) => (
  <SplitContainer>
    <SplitStack>
      <StackTitle align="flex-end">{leftTitle}</StackTitle>
      <StackBars
        resetCrosshairs={resetCrosshairs}
        updateCrosshairs={updateCrosshairs}
        crosshairs={crosshairs}
        inverted
        series={leftSeries}
      />
    </SplitStack>
    <CenterStack>
      <StackTitle align="center" />
      <StackLabels>
        {labels.map(label => (
          <StackLabel key={`center-stack-label-${label}`}>{label}</StackLabel>
        ))}
      </StackLabels>
    </CenterStack>
    <SplitStack>
      <StackTitle align="flex-start">{rightTitle}</StackTitle>
      <StackBars
        resetCrosshairs={resetCrosshairs}
        updateCrosshairs={updateCrosshairs}
        crosshairs={crosshairs}
        series={rightSeries}
      />
    </SplitStack>
    <OverlayStack>
      <StackTitle align="center" />
      <SplitLabelStack>
        <SplitLabel invert>
          {leftSeries
            .slice()
            .reverse()
            .map(({ p, y }, index) => (
              <SplitLabelPart
                // show
                key={`left-split-label-part-${Number(index)}`}
                show={crosshairs.length && y === crosshairs[0].y}>
                {numeral(p).format('0%')}
              </SplitLabelPart>
            ))}
        </SplitLabel>
        <CenterStack />
        <SplitLabel>
          {rightSeries
            .slice()
            .reverse()
            .map(({ p, y }, index) => (
              <SplitLabelPart
                // show
                key={`right-split-label-part-${Number(index)}`}
                show={crosshairs.length && y === crosshairs[0].y}>
                {numeral(p).format('0%')}
              </SplitLabelPart>
            ))}
        </SplitLabel>
      </SplitLabelStack>
    </OverlayStack>
  </SplitContainer>
)

export default SplitBars
