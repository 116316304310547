import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Modal from 'react-modal'

import DatePicker from './DatePicker'
import { dateOptions, createCompatibleRange } from '../utils/dates'

import { TitleDetail, TitleSmall, FigureSubtitle, Body } from './Text'
import Color from './Color'

const modalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: `${Color.Primary.Highlight}80`,
  },
}

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

const CancelButton = styled.div`
  background-color: ${Color.Primary.Highlight};
  color: ${Color.Primary.Main};
  padding: 1rem;
  font-weight: 800;
  display: inline;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: ${Color.Primary.Main};
    color: white;
  }
  transition: background-color 0.2s;
`

const BaseModal = ({ isOpen, close, title, children, showClose }) => (
  <Modal
    isOpen={isOpen}
    // onAfterOpen={this.afterOpenModal}
    onRequestClose={close}
    style={modalStyles}
    contentLabel="Example Modal">
    {showClose && <CancelButton onClick={close}>X</CancelButton>}
    <TitleSmall>{title}</TitleSmall>
    <ModalContentContainer>{children}</ModalContentContainer>
  </Modal>
)

const ArticleBox = styled.div`
  border: 3px solid ${Color.Primary.Main};
  margin-bottom: 1rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${Color.Primary.Highlight};
  }
`

const ArticleTitle = styled(FigureSubtitle)`
  margin: 0;
  margin-bottom: 0.5rem;
`

const ArticleDate = styled(TitleDetail)`
  margin: 0;
`

const ArticleModal = ({ isOpen, close, articles, changeArticle, showClose }) => (
  <BaseModal isOpen={isOpen} close={close} title="Choose Article:" showClose={showClose}>
    {articles.map(({ title, liveDate }, index) => (
      <ArticleBox onClick={() => changeArticle(articles[index])} key={`article-box-${title}`}>
        <ArticleTitle>{title}</ArticleTitle>
        <ArticleDate>{moment(liveDate).format('DD.MM.YY')}</ArticleDate>
      </ArticleBox>
    ))}
  </BaseModal>
)

const ClientModal = ({ isOpen, close, clients, changeClient, showClose }) => (
  <BaseModal isOpen={isOpen} close={close} title="Choose Client:" showClose={showClose}>
    {clients.map(({ name }, index) => (
      <ArticleBox onClick={() => changeClient(clients[index])} key={`article-box-${name}`}>
        <ArticleTitle>{name}</ArticleTitle>
      </ArticleBox>
    ))}
  </BaseModal>
)

const DateModal = ({ isOpen, close, minimumStartDate, startDate, endDate, consolidateDates, isLoading, showClose }) => (
  <BaseModal isOpen={isOpen} close={close} title="Date Range:" showClose={showClose}>
    {dateOptions.map(({ title, quantity, unit }) => {
      return (
        <ArticleBox
          onClick={() => {
            consolidateDates(
              createCompatibleRange({
                quantity,
                unit,
                minimumStartDate,
              })
            )
          }}
          key={`article-date-option-${title}`}>
          <ArticleTitle>{title}</ArticleTitle>
        </ArticleBox>
      )
    })}
    <ArticleBox>
      <ArticleTitle>Custom</ArticleTitle>
      <DatePicker
        mobile
        startDate={startDate}
        endDate={endDate}
        consolidateDates={consolidateDates}
        isLoading={isLoading}
        minimumStartDate={minimumStartDate}
      />
    </ArticleBox>
  </BaseModal>
)

const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const PasswordResetModal = ({ isOpen, close, resetComplete, errorMessage }) => (
  <BaseModal
    isOpen={isOpen}
    // onAfterOpen={this.afterOpenModal}
    close={close}
    title="Password Reset"
    showClose>
    <Centered>
      <Body>
        {resetComplete
          ? 'You will receive an email with instructions on how to reset your password'
          : 'Please contact TV 2 to reset your credentials.'}
      </Body>
      {errorMessage && <Body error>{errorMessage}</Body>}
    </Centered>
  </BaseModal>
)

const ErrorTitle = styled.h1`
  margin: 0;
  margin-bottom: 2rem;
`

const ErrorDetail = styled.h3`
  margin: 0;
  margin-bottom: 1rem;
`

const ErrorRetryButton = styled.div`
  padding: 0.4rem 1.2rem;
  background-color: white;
  margin-top: 2rem;
  cursor: pointer;
`

const ErrorModal = ({ isOpen, close, wantsReload }) => (
  <BaseModal
    isOpen={isOpen}
    // onAfterOpen={this.afterOpenModal}
    close={close}
    title="Error"
    // showClose
  >
    <ErrorTitle>Error loading dashboard data</ErrorTitle>
    <ErrorDetail>There may be a problem with your internet connection.</ErrorDetail>
    <ErrorDetail>Please refresh the page or click below to retry:</ErrorDetail>
    <ErrorRetryButton onClick={wantsReload}>RELOAD</ErrorRetryButton>
  </BaseModal>
)

export { ArticleModal, DateModal, PasswordResetModal, ErrorModal, ClientModal }
