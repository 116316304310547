import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import styled from 'styled-components'
import OverviewSection from './views/Overview'
import UsersSection from './views/Users'
import PageViewsSection from './views/PageViews'
import VideosSection from './views/Videos'
import ExportSection from './views/Export'

import { withAuthorization } from '../../components/Session'
import Color from '../../components/Color'
import Sidebar, { SidebarSpacer } from '../../components/Sidebar'
import Footer from '../../components/Footer'

import Icon, { IconName } from '../../components/Icon'

import * as SIZES from '../../constants/sizes'
import { Title, TitleSmall, Body } from '../../components/Text'
import LoadingScreen from '../../components/LoadingScreen'
import { Media } from '../../components/MediaQuery'

import { CoreError } from '../../constants/errors'

const SECTIONS = [
  {
    name: 'Overview',
    id: 'HOME',
    icon: 'Home',
  },
  {
    name: 'Users',
    id: 'USERS',
    icon: 'Users',
  },
  {
    name: 'Views',
    id: 'PAGEVIEWS',
    icon: 'Views',
  },
  {
    name: 'Videos',
    id: 'VIDEOS',
    icon: 'Video',
  },
  // {
  //   name: "Depth",
  //   id: "SCROLL_DEPTH",
  //   icon: "Scroll"
  // },
  {
    name: 'Export',
    id: 'EXPORT',
    icon: 'Export',
  },
]

const SECTION = {}
SECTIONS.forEach(({ id }, index) => {
  SECTION[id] = index
})

const PageHousing = styled.div`
  padding-top: ${SIZES.NAV_HEIGHT};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  ${Media.DesktopSmall`
    padding-top: 8rem;
  `};
  ${Media.Tablet`
    padding-top: 5rem;
  `};
`

const DashboardContainer = styled.div`
  background-color: #fafafa;
  width: calc(100% - 12rem);
  height: 100%;
  min-height: calc(100vh - ${SIZES.NAV_HEIGHT});
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2rem;
  ${Media.DesktopSmall`
  width: calc(100% - 4rem);
  padding-right: 2rem;
  `};
`

const DashboardWrapInner = styled.div`
  width: 100%;
  height: 100%;
  max-width: calc(${SIZES.MAX_WIDTH} - 10rem - 2rem);
  @media (max-width: ${SIZES.MAX_WIDTH}) {
    max-width: none;
    /* padding-left: 2rem */
  }
  /* margin-bottom: 10rem; */
  /* background-color: blue; */
`

const ArticleLinkContainer = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration-line: none;
  color: ${Color.Danger.Main};
  fill: ${Color.Danger.Main};
  margin: 0;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  &:hover {
    color: ${Color.Danger.Light};
    fill: ${Color.Danger.Light};
  }
  transition: color 0.2s, fill 0.2s;
  ${Media.DesktopSmall`
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
  `};
`

const ArticleLink = styled.h4`
  margin: 0;
  text-decoration: underline;
  @media print {
    font-size: 2rem;
    text-decoration: none;
    margin-bottom: 2rem;
  }
`

const ArticleLinkIcon = styled.div`
  width: 2rem;
  height: 2rem;
  @media print {
    display: none;
  }
`

const ArticleLinkOut = ({ title, link }) => (
  <ArticleLinkContainer href={link} target="_blank">
    <ArticleLink>{title}</ArticleLink>
    <ArticleLinkIcon>
      <Icon name={IconName.Link} />
    </ArticleLinkIcon>
  </ArticleLinkContainer>
)

const TitleWrap = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;
  ${Media.DesktopSmall`
    flex-direction: column;
    align-items: flex-start;
  `}
  ${Media.Tablet`
    margin-top: 4rem;
  `};
`

const SectionWrap = styled.div`
  width: 100%;
`

const SectionTitle = styled(Title)`
  margin: 0;
  color: ${Color.Black.Main};
  @media print {
    display: none;
  }
`

class Dashboard extends Component {
  state = {
    sectionIndex: 0,
  }

  componentDidMount = () => {
    const { requestData } = this.props
    requestData()
  }

  componentWillUnmount = () => {
    // console.log(`Dashboard | UNMOUNTING!!!`);
  }

  changeView = index => {
    const { odoVal } = this.state
    this.setState(
      {
        sectionIndex: index,
        odoVal: odoVal + Math.floor(Math.random() * 10000000),
      },
      () => window.scrollTo(0, 0)
    )
  }

  render = () => {
    const { sectionIndex } = this.state

    const { activeArticle, data, isLoading, firstLoadComplete, error, requestData, startDate, endDate } = this.props

    let activePane

    if (firstLoadComplete && error !== CoreError.BadOutput) {
      switch (sectionIndex) {
        case SECTION.SCROLL_DEPTH:
        case SECTION.EXPORT:
          activePane = (
            <ExportSection
              startDate={startDate}
              endDate={endDate}
              sections={[
                {
                  title: 'Overview',
                  component: <OverviewSection print dataset={data} isLoading={isLoading} />,
                },
                {
                  title: 'Users',
                  component: <UsersSection print dataset={data} isLoading={isLoading} />,
                },
                {
                  title: 'Views',
                  component: <PageViewsSection print dataset={data} isLoading={isLoading} />,
                },
                {
                  title: 'Videos',
                  component: <VideosSection print dataset={data} isLoading={isLoading} />,
                },
              ]}
            />
          )
          break
        case SECTION.HOME:
          activePane = <OverviewSection dataset={data} isLoading={isLoading} />
          break
        case SECTION.USERS:
          activePane = <UsersSection dataset={data} isLoading={isLoading} />
          break
        case SECTION.PAGEVIEWS:
          activePane = <PageViewsSection dataset={data} isLoading={isLoading} />
          break
        case SECTION.VIDEOS:
          activePane = <VideosSection dataset={data} isLoading={isLoading} />
          break
        default:
          break
      }
    } else if (error === CoreError.BadOutput) {
      activePane = (
        <div>
          <TitleSmall>Not Enough Data</TitleSmall>
          <Body>There is not enough data to generate a report for your chosen article and date range.</Body>
          <Body>
            Please try adjusting your date range to be wider, or more accurate to when your article was published.
          </Body>
        </div>
      )
    }

    return (
      <PageHousing>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <Sidebar index={sectionIndex} sections={SECTIONS} changeView={this.changeView} />
        <SidebarSpacer />
        <DashboardContainer id="page-housing">
          <DashboardWrapInner>
            <TitleWrap>
              <SectionTitle>{SECTIONS[sectionIndex].name}</SectionTitle>
              {activeArticle && (
                <ArticleLinkOut
                  title={activeArticle.title}
                  link={`https://annonse.tv2.no${activeArticle.clientPath.slice(0, -1)}${activeArticle.path}`}
                />
              )}
            </TitleWrap>
            <SectionWrap>{activePane}</SectionWrap>
            <Footer />
          </DashboardWrapInner>
        </DashboardContainer>
        {/* <ErrorModal isOpen={error === CoreError.NoData} wantsReload={requestData} /> */}
        <LoadingScreen
          reveal={!firstLoadComplete}
          error={error}
          wantsReload={requestData}
          wantsSignOut={this.props.wantsSignOut}
        />
      </PageHousing>
    )
  }
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(Dashboard)
