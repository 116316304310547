import styled, { css, keyframes } from 'styled-components'
import Color from './Color'

const loadingBarKeyframes = keyframes`
  0%  { transform: translateX(-102%) }
  45% { transform: translateX(0) }
  55% { transform: translateX(0) }
  90% { transform: translateX(102%) }
  100% { transform: translateX(102%) }
`

const LoadingBar = styled.div`
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  overflow-x: hidden;
  background-color: ${props =>
    props.loading ? Color.Primary.Fade : Color.Primary.Fade}; /* use px color $primary-light */
  /* opacity: ${props => (props.loading ? 1.0 : 0.0)}; */
  transition: background-color 0.2s;

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 102%;
    background-color: ${Color.Primary.Main}; /* use px color $primary-default */
    ${props =>
      props.loading &&
      css`
        animation: ${loadingBarKeyframes} 1.5s cubic-bezier(0.5, 0.01, 0.51, 1) infinite;
      `}
    opacity: ${props => (props.loading ? 1.0 : 0.0)};
    transition: opacity 0.2s;
  }
`

export default LoadingBar
