import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import Color from '../../../components/Color'
import { Font, Size, TitleSmall, TitleDetail, Title } from '../../../components/Text'
import { HideFromPrint } from '../../../components/Layout'

const Page = styled.div`
  page-break-after: always;
`

const Paper = styled.div`
  width: ${595 * 1.1}px;
  background-color: #ddd;
  pointer-events: none;
  opacity: 0;

  @media print {
    opacity: 1;
  }
`

const ExportContainer = styled.div`
  height: 20vh;
  overflow: hidden;
  @media print {
    overflow: unset;
    height: 100%;
  }
`

const PrintButton = styled.div`
  display: inline-block;
  padding: 0.4rem 1.2rem;
  background-color: ${Color.Primary.Highlight};
  color: ${Color.Primary.Main};
  border: 3px solid ${Color.Primary.Main};
  font-family: ${Font.Heading};
  font-size: ${Size.Medium};
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 2rem;

  &:hover {
    background-color: ${Color.Primary.Main};
    color: white;
  }

  transition: background-color 0.2s, color 0.2s;
`

const Export = ({ sections, startDate, endDate }) => (
  <ExportContainer>
    <HideFromPrint>
      <PrintButton onClick={() => window.print()}>Print</PrintButton>
    </HideFromPrint>
    <Paper id="export-housing">
      <Title>TV2 Reporting</Title>
      <TitleSmall>
        Date Range: {moment(startDate).format('DD.MM.YY')}–{moment(endDate).format('DD.MM.YY')}
      </TitleSmall>
      <TitleDetail>Generated on {moment().format('DD.MM.YY')}</TitleDetail>
      <Page />
      {sections.map(({ title, component }) => (
        <Page key={`export-page-${title}`}>
          <h1>{title}</h1>
          {component}
        </Page>
      ))}
    </Paper>
  </ExportContainer>
)

export default Export
