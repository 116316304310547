import React, { Fragment } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { TitleSmall, FigureTitle } from './Text'
import Color from './Color'

import { ElementType } from '../constants/reporting'
import { Media } from './MediaQuery'
import TooltipIcon from './TooltipIcon'

const PaneHeight = {
  Short: '10rem',
  Medium: '22rem',
  Tall: '30rem',
}

// Pane 💀 //
const skeletonAnimation = keyframes`
  0% {
    background-position-x: -100px;
  }
40%, 100% {
    background-position-x: -50%;
  }
`

const PaneSkeleton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    ${props => (props.filled ? '#FFBF94' : 'transparent')} 0px,
    #ffddca 40px,
    ${props => (props.filled ? '#FFBF94' : 'transparent')} 80px
  );
  background-size: 300%;
  animation: ${skeletonAnimation} 1.5s infinite ease-in-out;
  opacity: ${props => (props.isLoading ? 0.44 : 0)};
  transition: opacity 0.2s;
  pointer-events: ${props => (props.isLoading ? 'auto' : 'none')};
`

// Pane 🔠 //
const PaneFigure = styled(FigureTitle)`
  margin: 4rem ${props => (props.info ? '4rem' : '0')};
  font-size: ${props => (props.info ? '1.2rem' : '3rem')};
  color: ${props => (props.info ? Color.Danger.Main : Color.Primary.Main)};
  opacity: ${props => (props.isLoading ? '0' : '1')};
  transition: opacity 0.2s;
`

const PaneTitle = styled(TitleSmall)`
  margin-bottom: 1rem;
`

const PaneTitleRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const gradient = css`
  background: ${Color.Monochrome.Highlight};
`

const PaneRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 4rem;
  ${Media.DesktopSmall`
    flex-direction: column;
  `};
  ${Media.Tablet`
    margin: 1rem 0;
  `};
`

const PaneBox = styled.div`
  /* border: 2px solid black; */
  height: ${props => props.height};
  ${props => (props.shouldFill ? gradient : 'background: white')};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1), 0 3px 9px rgba(0, 0, 0, 0.12);
  position: relative;
  ${props => props.table && 'overflow-y: scroll'};
`

const PaneChart = styled.div`
  ${props => !props.table && 'height: 18rem'};
  width: 100%;
  padding: 2rem;
  ${Media.Tablet`
    padding: 0.4rem;
  `};
`

const PaneStyle = styled.div`
  flex: ${props => props.flex || 1};
  ${Media.DesktopSmall`
    width: 100%;
  `};
`

const PaneHousing = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - ${props => (props.position === 'inner' ? 4 : 2)}rem);
  padding-right: ${props => (props.position === 'first' || props.position === 'inner' ? '1rem' : '0')};
  padding-left: ${props => (props.position === 'last' || props.position === 'inner' ? '1rem' : '0')};
  ${Media.DesktopSmall`
    width: 100%;
    padding: 0;
  `}
`

const PaneTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Pane = ({
  extraContent,
  elementType,
  height,
  title,
  tooltip,
  titleRight,
  value,
  flex,
  position,
  fill,
  icon,
  info,
  isLoading,
  table,
}) => {
  let paneContent = null
  switch (elementType) {
    case ElementType.Series:
      paneContent = (
        <Fragment>
          <PaneChart table={table}>{value}</PaneChart>
          <PaneSkeleton filled isLoading={isLoading} />
        </Fragment>
      )
      break
    case ElementType.Figure:
    default:
      paneContent = (
        <Fragment>
          <PaneFigure info={info} isLoading={isLoading}>
            {value}
          </PaneFigure>
          <PaneSkeleton isLoading={isLoading} />
        </Fragment>
      )
  }
  return (
    <PaneStyle flex={flex} position={position}>
      <PaneHousing>
        <PaneTop>
          <PaneTitle>
            {title}
            {tooltip && <TooltipIcon text={tooltip} />}
          </PaneTitle>
          <PaneTitleRight>{titleRight}</PaneTitleRight>
        </PaneTop>
        <PaneBox height={height} shouldFill={fill} icon={icon}>
          {paneContent}
        </PaneBox>
        {extraContent}
      </PaneHousing>
    </PaneStyle>
  )
}

export { Pane, PaneRow, PaneHeight }
