import React, { Component } from 'react'
import DataTable from 'react-data-table-component'

import Color from '../../../components/Color'

import { computeElementForDatapoint } from '../data-parsing'
import { DataPoint, ElementType } from '../../../constants/reporting'

import { Pane, PaneRow, PaneHeight } from '../../../components/Pane'
import Chart from '../../../components/Chart'
import ChartType from '../../../constants/charting'
import { PageBreak } from '../../../components/Layout'
import { PageViewsChart } from '../../../components/FormattedPane'
import { Mobile } from '../../../components/MediaQuery'

class PageViews extends Component {
  state = {
    showPageViewsAsCumulative: false,
  }

  render = () => {
    const { dataset, isLoading, print } = this.props
    const { showPageViewsAsCumulative } = this.state
    const pageViewsTotal = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Figure,
      dataPoint: DataPoint.PageViews,
    })
    const pageViewsUniqueTotal = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Figure,
      dataPoint: DataPoint.PageViewsUnique,
    })
    const averageTimeOnPage = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Figure,
      dataPoint: DataPoint.PageAverageTime,
    })
    const pageViewsSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.PageViews,
    })
    const scrollDepthSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.PageScrollDepth,
    })

    // Table Data
    const linkClicksSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.LinkClicksUnique,
    })

    return (
      <div>
        <PaneRow>
          <Pane
            fill
            height={PaneHeight.Short}
            position="first"
            elementType={ElementType.Figure}
            title="Page Views"
            tooltip="A page view is tracked every time someone loads the article page. One person might have multiple page views<br>if they refresh the browser, or visit the article multiple times in the given date period."
            value={pageViewsTotal}
            isLoading={isLoading}
          />
          <Pane
            fill
            height={PaneHeight.Short}
            position="inner"
            elementType={ElementType.Figure}
            title="Unique Page Views"
            tooltip="Unique page views are only tracked once per user per session. If someone refreshes the article page 5 times,<br>then that would track 5 page views, but only 1 unique page view."
            value={pageViewsUniqueTotal}
            isLoading={isLoading}
          />
          <Pane
            fill
            height={PaneHeight.Short}
            position="last"
            elementType={ElementType.Figure}
            title="Avg. Time on Page"
            tooltip="The average time spent on the article."
            value={averageTimeOnPage}
            isLoading={isLoading}
          />
        </PaneRow>
        <PageBreak />
        {pageViewsSeries && (
          <>
            <PageViewsChart
              data={pageViewsSeries}
              isLoading={isLoading}
              showPageViewsAsCumulative={showPageViewsAsCumulative}
              checkCumulative={checked => this.setState({ showPageViewsAsCumulative: checked })}
            />
            <PageBreak />
          </>
        )}
        {scrollDepthSeries && (
          <PaneRow>
            <Pane
              height={PaneHeight.Medium}
              isLoading={isLoading}
              position="first"
              flex={2}
              elementType={ElementType.Series}
              title="Scroll Depth"
              tooltip="A representation of how far people scroll. Scrolling 100% of the page means you’ve scrolled to the bottom of the article."
              value={
                <Chart
                  series={scrollDepthSeries.points}
                  type={ChartType.BarPercentages}
                  yDomain={scrollDepthSeries.yDomain}
                  xDomain={scrollDepthSeries.xDomain}
                />
              }
            />
          </PaneRow>
        )}
        {linkClicksSeries && (
          <PaneRow>
            <Pane
              // height={PaneHeight.Tall}
              flex={1}
              elementType={ElementType.Series}
              table
              title="Link Clicks"
              tooltip="This table explains which links people clicked in the article.<br>Multiple links may point to the same URL, so we’ve grouped each unique URL per row."
              value={
                <Mobile>
                  {isMobile => (
                    <DataTable
                      noDataComponent="Link tracking not set up for this article"
                      columns={linkClicksSeries.columns}
                      data={linkClicksSeries.rows}
                      pagination={!isMobile && !print}
                      responsive={isMobile || print}
                      noHeader
                      customTheme={{
                        header: {
                          fontSize: '1.2rem',
                          fontColor: Color.Primary.Main,
                        },
                      }}
                    />
                  )}
                </Mobile>
              }
              isLoading={isLoading}
            />
          </PaneRow>
        )}
      </div>
    )
  }
}

export default PageViews
