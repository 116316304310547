import React from 'react'
import styled from 'styled-components'

import * as SIZES from '../constants/sizes'
import Icon, { IconName } from './Icon'
import Color from './Color'
import { Media } from './MediaQuery'

import konseptLogo from '../media/images/logo_horizontal_lilla.svg'

const SidebarContainer = styled.div`
  position: fixed;
  top: ${SIZES.NAV_HEIGHT};
  left: 0;
  height: calc(100vh - ${SIZES.NAV_HEIGHT});

  z-index: 100;
  /* padding-left: calc((100vw - ${SIZES.MAX_WIDTH}) * 0.5 - 17rem); */
  width: calc(12rem - 3px);
  height: 100vh;
  background-color: #fbfbfb;
  border-right: 3px solid ${Color.Monochrome.Main};
  ${Media.DesktopSmall`
    top: initial;
    bottom: 0;
    height: 6rem;
    width: 100%;
    border-top: 1px solid ${Color.Monochrome.Light};
    display: flex;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
  `}
  ${Media.Tablet`
    height: 3rem;
  `};

  @media print {
    display: none;
  }
`

const SidebarShifter = styled.div`
  position: absolute;
  width: 100%;
  /* height: ${props => `${4 + (props.index === 0 ? 0.5 : 0)}rem`}; */
  height: 4rem;
  background-color: ${Color.Secondary.Light};
  z-index: 5;
  top: 0;
  /* top: ${props => `${props.index * 4 + (props.index === 0 ? 0 : 0.5)}rem`}; */
  transform: translateY(${props => props.index * 4 + (props.index === 0 ? 0 : 0.5)}rem) scaleY(${props =>
  props.index === 0 || props.index === 4 ? 1.125 : 1});
  transform-origin: top center;
  transition: transform .4s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* transition: top .4s ease-in-out, height .4s ease-in-out; */
  ${Media.DesktopSmall`
    height: 6rem;
    transform: translateX(${props => props.index * 4 + (props.index === 0 ? 0 : 0.5)}rem) scaleY(${props =>
    props.index === 0 || props.index === 4 ? 1.125 : 1});
    width: 2rem;
    display: none;
  `}
`

const LogoImage = styled.img`
  width: 60%;
`

const LogoContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  bottom: 4rem;
  width: 100%;
  left: 0;
  /* background-color: green; */

  ${Media.DesktopSmall`
    display: none;
  `};
`

const SidebarLogo = () => (
  <LogoContainer>
    <LogoImage src={konseptLogo} />
  </LogoContainer>
)

const SidebarSpacer = styled.div`
  width: 12rem;
  ${Media.DesktopSmall`
  display: none;
  `};
`

const SidebarSelector = styled.div`
  z-index: 10;
  height: 3rem;
  margin: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 10rem;
  color: ${props => (props.selected ? Color.Black.Dark : Color.Black.Main)};
  fill: ${props => (props.selected ? Color.Black.Dark : Color.Black.Main)};
  transition: color 0.2s, fill 0.2s;

  &:hover {
    color: ${Color.Black.Dark};
    fill: ${Color.Black.Dark};
  }

  ${Media.DesktopSmall`
    flex: 1;
    width: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${props => (props.selected ? Color.Primary.Highlight : 'transparent')};
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
  `};
  ${Media.Tablet`
      margin: 0.5rem;
      height: 2rem;
      padding: 0;
  `};
`

const SidebarIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  z-index: 10;
`

const SidebarTitle = styled.div`
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: normal;
  font-family: tv2sans-medium;
  z-index: 10;
  /* color: ${props => (props.selected ? Color.Primary.Main : Color.Ghost.Fade)}; */
  /* background-color: yellow; */
  ${Media.DesktopSmall`
    font-size: 100%;
    margin: 0;
    /* margin-bottom: 0.5rem; */
  `}
  ${Media.Tablet`
    display: none;
  `};
`

const Sidebar = ({ index: sectionIndex, sections, changeView }) => (
  <SidebarContainer>
    <SidebarShifter index={sectionIndex} />
    {sections.map((section, index) => (
      <SidebarSelector
        selected={sectionIndex === index}
        onClick={() => changeView(index)}
        key={`sidebar-selector-${section.name}`}>
        <SidebarIcon selected={sectionIndex === index}>
          <Icon name={IconName[section.icon]} selected={sectionIndex === index} fill={Color.Black.Main} />
        </SidebarIcon>
        <SidebarTitle selected={sectionIndex === index}>{section.name}</SidebarTitle>
      </SidebarSelector>
    ))}
    <SidebarLogo src={konseptLogo} />
  </SidebarContainer>
)

export default Sidebar
export { SidebarSpacer }
