import styled from 'styled-components'

import Color from '../Color'

const Font = {
  Heading: 'tv2sans-bold',
  HeadingSlim: 'tv2sans-light',
  Body: 'tv2sans-regular',
}

const Size = {
  Small: '1rem',
  Medium: '1.3rem',
  Large: '2.6rem',
  Extra: '3.2rem',
}

const Weight = {
  Light: 200,
  Regular: 400,
  Medium: 500,
  Heavy: 600,
  Black: 800,
}

const Title = styled.h1`
  font-family: 'tv2sans-condensed-bold';
  font-size: ${Size.Extra};
  color: ${Color.Ghost.Main};
  font-weight: normal;
`

const TitleSmall = styled.h2`
  font-family: 'tv2sans-condensed-bold';
  font-size: ${Size.Medium};
  font-weight: normal;
  margin-bottom: 1rem;
  text-transform: none;
  color: ${Color.Black.Main};
`

const TitleDetail = styled(TitleSmall)`
  font-family: 'tv2sans-bold';
  font-size: ${Size.Small};
  font-weight: normal;
  margin: 0;
  ${props => props.marginRight && 'margin-right: 0.6rem'};
`

const FigureTitle = styled.h3`
  font-family: 'tv2sans-regular';
  font-size: ${Size.Big};
  font-weight: normal;
`

const FigureSubtitle = styled.h4`
  font-family: 'tv2sans-regular';
  font-weight: normal;
  font-size: ${Size.Small};
`

const Body = styled.p`
  font-family: 'tv2sans-regular';
  font-weight: normal;
  font-size: ${Size.Medium};
  ${props => props.error && `color: ${Color.Black.Main}`};
`

export { Title, TitleSmall, TitleDetail, FigureTitle, FigureSubtitle, Body, Font, Weight, Size }
