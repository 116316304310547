import React from 'react'
import styled from 'styled-components'
import { Media } from './MediaQuery'
import { Font } from './Text'

const FooterContainer = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: yellow; */
  margin-bottom: 2rem;
  margin-top: 2rem;
  ${Media.DesktopSmall`
    width: 100%;
    margin-bottom: 8rem;
  `}
  @media print {
    display: none;
  }
`

const FooterSpacer = styled.div`
  height: 1px;
  flex: 1;
  border-bottom: 2px solid #ccc;
  ${Media.DesktopSmall`
    display: none;
  `}
`

const FooterText = styled.p`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Font.Heading};
  font-size: 1.4rem;
  color: #ccc;
`

const Footer = () => (
  <FooterContainer>
    <FooterSpacer />
    <FooterText>TV2 KONSEPT &copy; 2019</FooterText>
    <FooterSpacer />
  </FooterContainer>
)

export default Footer
