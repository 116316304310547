import React from 'react'
import styled from 'styled-components'

import { TitleDetail, TitleSmall } from './Text'
import Color from './Color'

const ErrorBackground = styled.div`
  background-color: ${Color.Secondary.Main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`

const ErrorTitle = styled(TitleDetail)`
  color: white;
  user-select: none;
  margin-bottom: 0.8rem;
`

const RetryButtonText = styled(TitleSmall)`
  color: ${Color.Secondary.Main};
  font-weight: 800;
`

const RetryButton = styled.div`
  background-color: white;
  color: ${Color.Secondary.Light};
  padding: 0.2rem 1rem;
  &:hover {
    background-color: ${Color.Secondary.Fade};
  }
  transition: background-color 0.2s;
`

const ErrorToast = ({ wantsRetry }) => (
  <ErrorBackground>
    <ErrorTitle>Error Loading Data</ErrorTitle>
    <RetryButton onClick={wantsRetry}>
      <RetryButtonText>RETRY</RetryButtonText>
    </RetryButton>
  </ErrorBackground>
)

export default ErrorToast
