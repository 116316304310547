import React from 'react'

import AuthUserContext from './context'
import { withFirebase } from '../Firebase'

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    state = {
      authUser: null,
    }

    componentDidMount = () => {
      const { firebase } = this.props
      this.listener = firebase.auth.onAuthStateChanged(authUser => this.manageAuth(authUser))
    }

    componentWillUnmount = () => {
      this.listener()
    }

    manageAuth = authUser => {
      this.setState({
        authUser: authUser || null,
      })
    }

    render = () => {
      const { authUser } = this.state
      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
