import React, { Component } from 'react'

import { RadialChart } from 'react-vis'

import Color from '../Color'
import { Font, Weight } from '../Text'

class PieChart extends Component {
  sendCrosshair = d => {
    const { updateCrosshairs } = this.props
    updateCrosshairs({ y: d.lol })
  }

  render = () => {
    const { data, resetCrosshairs, crosshairs } = this.props
    return (
      <RadialChart
        width={300}
        height={250}
        data={data
          .slice()
          .reverse()
          .map(({ name, angle }, index) => {
            const output = { name, angle, lol: index }
            if (crosshairs.length && crosshairs[0].y === index) {
              output.color = Color.Secondary.Light
            } else {
              output.color = Color.Secondary.Main
            }
            return output
          })}
        style={{ stroke: '#fff', strokeWidth: 4 }}
        getLabel={d => d.name}
        colorType="literal"
        onValueMouseOver={this.sendCrosshair}
        onValueMouseOut={resetCrosshairs}
        labelsStyle={{
          fontSize: 16,
          fill: '#222',
          fontFamily: Font.Heading,
          fontWeight: Weight.Heavy,
        }}
        showLabels
      />
    )
  }
}

export default PieChart
