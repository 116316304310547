import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'

import { withAuthentication } from './Session'
import Color from './Color'
import { PasswordResetModal } from './Modal'

const INITIAL_STATE = {
  username: '',
  emailAddress: '',
  password: '',
  error: null,
  resetModalOpen: false,
  resetEmailAddress: '',
  resetComplete: false,
  resetLoading: false,
  resetError: null,
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 20rem;
`

const baseInputStyle = css`
  margin: 1rem 0;
  border: none;
  &:focus {
    outline: none;
  }
`

const Input = styled.input`
  ${baseInputStyle}
  font-family: 'tv2sans-condensed-light';
  padding: 1rem 0.4rem 1rem;
  background-color: #fff;
  border: 0.1rem solid ${Color.Secondary.Light};
  font-size: 1rem;
  &:focus,
  &:hover {
    border: 0.1rem solid ${Color.Secondary.Main};
  }
  transition: border-bottom-color 0.4s;
`

const Submit = styled.button`
  ${baseInputStyle}
  font-family: 'tv2sans-condensed-light';
  margin-top: 2rem;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: ${Color.Secondary.Main};
  border-radius: 2rem;
  color: ${Color.Black.Main};
  transition: background-color 0.4s;
  cursor: pointer;

  &:hover {
    background-color: ${Color.Secondary.Light};
  }
`

const ErrorBackground = styled.div`
  background-color: ${Color.Danger.Fade};
  padding: 1rem;
`

const ErrorTitle = styled.h4`
  color: ${Color.Danger.Main};
  margin: 0 0 0.4rem;
`

const ErrorMessage = styled.p`
  color: ${Color.Danger.Main};
  margin: 0;
`

const PasswordReset = styled.span`
  margin-left: auto;
  color: #fff;
  font-size: 0.8rem;
  font-weight: normal;
  cursor: pointer;
  text-decoration: underline;
`

const FormError = ({ message }) => (
  <ErrorBackground>
    <ErrorTitle>Error:</ErrorTitle>
    <ErrorMessage>{message}</ErrorMessage>
  </ErrorBackground>
)

class LoginForm extends Component {
  state = { ...INITIAL_STATE }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = event => {
    const { username, password } = this.state
    const { firebase } = this.props
    firebase
      .doSignInWithEmailAndPassword(username, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        // history.push(ROUTES.DASHBOARD);
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  sendPasswordReset = async () => {
    const { resetEmailAddress: emailAddress } = this.state
    const { firebase } = this.props
    this.setState({
      resetLoading: true,
    })
    try {
      await firebase.doPasswordReset(emailAddress)
      this.setState({
        resetComplete: true,
        resetLoading: false,
        resetError: null,
      })
    } catch (error) {
      this.setState({
        resetLoading: false,
        resetError: error.message,
      })
    }
  }

  render = () => {
    const {
      username,
      password,
      error,
      resetModalOpen,
      resetEmailAddress,
      resetComplete,
      resetLoading,
      resetError,
    } = this.state
    return (
      <Form onSubmit={this.onSubmit}>
        <Input name="username" value={username} onChange={this.onChange} type="username" placeholder="username" />
        <Input name="password" value={password} onChange={this.onChange} type="password" placeholder="password" />
        <PasswordReset onClick={() => this.setState({ resetModalOpen: true })}>Forgotten your password?</PasswordReset>
        <PasswordResetModal
          isOpen={resetModalOpen}
          close={() =>
            this.setState({
              resetModalOpen: false,
              resetComplete: false,
              resetLoading: false,
              resetEmailAddress: '',
              resetError: null,
            })
          }
          email={resetEmailAddress}
          updateEmail={this.onChange}
          isLoading={resetLoading}
          requestReset={this.sendPasswordReset}
          resetComplete={resetComplete}
          errorMessage={resetError}
        />
        <Submit type="submit">Log In</Submit>
        {error && <FormError message={error.message} />}
      </Form>
    )
  }
}

export default withRouter(withAuthentication(LoginForm))
