import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { FlexibleXYPlot, Crosshair, XAxis, YAxis, LineSeries, VerticalBarSeries } from 'react-vis'
import numeral from 'numeral'

import Color from '../Color'
import ChartType from '../../constants/charting'

const CrosshairBox = styled.div`
  padding: 0.4rem;
  background-color: rgba(255, 255, 255, 0.8);
`

const CrosshairTitle = styled.h1`
  color: #222;
  margin: 0;
  margin-bottom: 0.4rem;
`

const CrosshairSubtitle = styled.h2`
  color: ${Color.Danger.Main};
  margin: 0;
`

class Chart extends Component {
  state = {
    crosshairs: [],
  }

  resetCrosshairs = () => this.setState({ crosshairs: [] })

  updateCrosshairs = (coords, isSeries, series) => {
    if (isSeries === true) {
      const { x } = coords
      let y = 0
      series
        .map(({ data }) => data)
        .forEach(points =>
          points.forEach(coord => {
            if (coord.x === x) {
              y += coord.y
            }
          })
        )
      this.setState({ crosshairs: [{ x, y }] })
    } else {
      this.setState({ crosshairs: [coords] })
    }
  }

  render = () => {
    const { series, type, xDomain, yDomain, xType: passedXType, stackBy } = this.props
    const { crosshairs } = this.state

    // Initialisation
    let graph = <h1>No Data For This Article / Date Range</h1>
    const animation = 'no-wobble'
    const { Main: chartColor } = Color.Primary
    const { Main: crosshairColor } = Color.Danger

    let xType = 'linear'
    const xTickLabelAngle = 0
    let xTickTotal = 5
    let xTickFormat = tick => tick

    const yTickTotal = 5
    let yTickFormat = tick => numeral(tick).format(yDomain && yDomain[1] < 5e3 ? '0.0a' : '0a')

    let crosshairOutput = null

    // Chart type changes
    if (series != null && series.length) {
      switch (type) {
        case ChartType.LineDates:
          xType = 'time' // 'ordinal' ?
          // xTickLabelAngle = -15;
          xTickTotal = 5
          xTickFormat = tick => {
            // const tickFormat = baseFormat(tick);
            // const exclusionList = [
            //   baseFormat(series[0].x),
            //   baseFormat(series[series.length - 1].x)
            // ];
            // if (exclusionList.includes(tickFormat)) {
            //   return "";
            // }
            return moment(tick).format('Do MMM')
          }
          graph = (
            <LineSeries
              curve="curveMonotoneX"
              strokeWidth={4}
              color={chartColor}
              data={series}
              onNearestX={this.updateCrosshairs}
            />
          )
          crosshairOutput =
            crosshairs.length &&
            // crosshairs.map(({ x, y }) => ({
            //   x: moment(x).format("DD-MM-YYYY"),
            //   y: numeral(y).format("0,0")
            // }))[0];
            crosshairs[0]
          break
        case ChartType.LinePercentages:
          xTickFormat = tick => `${tick}%`
          xTickTotal = 10
          graph = series.map(({ name, color, data }) => (
            <LineSeries
              curve="curveMonotoneX"
              strokeWidth={4}
              color={color}
              data={data}
              onNearestX={coords => this.updateCrosshairs(coords, true, series)}
            />
          ))
          crosshairOutput = crosshairs.length && crosshairs[0].y
          break
        case ChartType.BarStacked:
          yTickFormat = tick => numeral(tick * 0.01).format('0%')
          xType = passedXType
          xTickTotal = series[0].length
          graph = series.map(({ name, color, data }) => (
            <VerticalBarSeries
              color={color}
              data={data}
              onNearestX={coords => coords.y < 100 && this.updateCrosshairs(coords)}
            />
          ))
          crosshairOutput = crosshairs.length && crosshairs[0].y
          break
        case ChartType.BarPercentages:
          xTickFormat = tick => `${tick}%`
          xTickTotal = 10
          graph = (
            <VerticalBarSeries barWidth={0.4} color={chartColor} data={series} onNearestX={this.updateCrosshairs} />
          )
          crosshairOutput = crosshairs.length && crosshairs[0].y
          break
        default:
          return null
      }
    }
    return (
      <FlexibleXYPlot
        onMouseLeave={this.resetCrosshairs}
        animation={animation}
        xType={xType}
        xDomain={xDomain}
        yDomain={yDomain}
        stackBy={stackBy}>
        <XAxis tickTotal={xTickTotal} tickLabelAngle={xTickLabelAngle} tickFormat={xTickFormat} />
        <YAxis tickTotal={yTickTotal} tickFormat={yTickFormat} />
        {graph}
        <Crosshair
          style={{
            line: { background: crosshairColor, width: '2px' },
            title: { color: '#444' },
          }}
          values={crosshairs}>
          <CrosshairBox>
            <CrosshairTitle>
              {type === ChartType.BarStacked
                ? numeral((crosshairOutput.y ? crosshairOutput.y : crosshairOutput) * 1e-2).format('0.0%')
                : numeral(crosshairOutput.y ? crosshairOutput.y : crosshairOutput).format('0,0')}
            </CrosshairTitle>
            {type === ChartType.LineDates && (
              <CrosshairSubtitle>{moment(crosshairOutput.x).format('DD.MM.YY')}</CrosshairSubtitle>
            )}
          </CrosshairBox>
        </Crosshair>
      </FlexibleXYPlot>
    )
  }
}

export default Chart
