import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import AuthUserContext from './context'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount = () => {
      const { firebase, history } = this.props
      this.listener = firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          history.push(ROUTES.LOG_IN)
        }
      })
    }

    componentWillUnmount = () => {
      this.listener()
    }

    render = () => (
      <AuthUserContext.Consumer>
        {authUser =>
          condition(authUser) ? (
            <Component {...this.props} />
          ) : // <Redirect to={ROUTES.LOG_IN} />
          null
        }
      </AuthUserContext.Consumer>
    )
  }

  return compose(withRouter, withFirebase)(WithAuthorization)
}

export default withAuthorization
