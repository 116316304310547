import moment from 'moment'
import React, { Component } from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Navigation from './components/Navigation'
import { withAuthentication } from './components/Session'
import { CoreError } from './constants/errors'
import * as ROUTES from './constants/routes'
import Dashboard from './containers/dashboard'
import { consumeBatchData } from './containers/dashboard/data-parsing'
import Login from './containers/login'

import ErrorNotification from './components/ErrorNotification'

const yesterday = moment().subtract('1', 'day')

const initialState = {
  clients: [],
  articles: [],
  activeArticle: null,
  activeClient: null,
  data: [],
  minimumStartDate: moment(yesterday).subtract(1, 'month'),
  startDate: moment(yesterday).subtract(1, 'month'),
  endDate: moment(yesterday),
  isLoading: true,
  firstLoadComplete: false,
  errorType: CoreError.None,
  isAdmin: false,
}

class App extends Component {
  state = {
    ...initialState,
  }

  changeDates = ({ startDate, endDate }) => {
    this.setState(
      {
        startDate,
        endDate,
        isLoading: true,
      },
      () => this.loadDashboardData({ populateArticles: false })
    )
  }

  changeArticle = chosenArticle => {
    const { activeArticle } = this.state
    const { id: activeArticleId } = activeArticle
    const { id: chosenArticleId, liveDate, lastCacheDate, endDate: articleEndDate } = chosenArticle

    if (activeArticleId === chosenArticleId || chosenArticleId === '' || chosenArticleId == null) {
      return
    }

    const startDate = moment(liveDate)
    const minimumStartDate = moment(liveDate)
    let endDate = articleEndDate ? moment(articleEndDate) : moment(lastCacheDate)

    if (endDate.isAfter(yesterday) || endDate.isBefore(startDate)) {
      endDate = yesterday
    }

    this.setState({ startDate, minimumStartDate, endDate, activeArticle: chosenArticle }, () =>
      this.loadDashboardData({ populateArticles: false })
    )
  }

  changeClient = chosenClient => {
    const { activeClient } = this.state
    const { id: activeClientId } = activeClient
    const { id: chosenClientId } = chosenClient

    if (activeClientId === chosenClientId || !chosenClientId) return

    this.setState({
      activeClient: chosenClient,
    })

    this.loadDashboardData({ populateArticles: true })
  }

  isSignedIn = () => {
    const { firebase } = this.props
    return firebase.auth.currentUser != null
  }

  isAdminUser = async () => {
    const { firebase } = this.props
    const isAdmin = await firebase.isAdmin()
    return firebase.auth.currentUser != null && isAdmin
  }

  wantsSignOut = async () => {
    const { firebase } = this.props
    await firebase.doSignOut()
    this.setState(
      {
        ...initialState,
      },
      () => {
        window.location.reload()
      }
    )
  }

  errorNotification = ({ populateArticles }) => {
    toast(
      <ErrorNotification
        wantsRetry={() => {
          this.loadDashboardData({ populateArticles })
          toast.dismiss()
        }}
      />
    )
  }

  getFirstUserIdFromClient = client => {
    return client?.members?.[0]
  }

  getArticles = async () => {
    const { firebase } = this.props
    const { activeClient } = this.state

    const response = await firebase.cloudFunction('getArticlesForUser', {
      userId: this.getFirstUserIdFromClient(activeClient),
    })

    return response.data
  }

  initAdminContext = async () => {
    const { firebase } = this.props

    // fetch list of clients
    const { data: clients } = await firebase.cloudFunction('getAllClients')
    const sortedClients = clients.sort((a, b) => a.name.localeCompare(b.name))
    const firstClient = sortedClients?.[1]

    this.setState({
      isAdmin: true,
      clients: sortedClients,
      activeClient: firstClient,
    })
  }

  loadDashboardData = async ({ populateArticles }) => {
    const { firebase } = this.props
    const isAdmin = await this.isAdminUser()

    if (isAdmin && this.state.articles.length < 1) {
      await this.initAdminContext()
    }

    this.setState({ isLoading: true })

    if (!this.isSignedIn()) {
      this.setState({
        errorType: CoreError.NotAuthorised,
        isLoading: false,
      })
      return
    }

    let newState = {}

    let articles = this.state.articles.slice()
    console.log(articles)
    let activeArticle = null
    let startDate = moment(this.state.startDate)
    let endDate = moment(this.state.endDate)

    if (populateArticles) {
      try {
        articles = await this.getArticles()

        if (articles.length === 0) {
          throw new Error('At least 1 article is required.')
        }
      } catch (error) {
        console.error(error)
        this.setState(
          {
            errorType: CoreError.NoData,
          },
          () => this.errorNotification({ populateArticles: true })
        )
        return
      }

      const [firstArticle] = articles.sort(
        ({ liveDate: a }, { liveDate: b }) => moment(b).valueOf() - moment(a).valueOf()
      )

      if (!firstArticle) {
        throw new Error('No first article found.')
      }

      activeArticle = firstArticle

      const { liveDate, lastCacheDate, endDate: articleEndDate } = activeArticle
      startDate = moment(liveDate)

      if (articleEndDate && articleEndDate > liveDate) {
        endDate = moment(articleEndDate)
      } else if (!articleEndDate && lastCacheDate) {
        endDate = moment(lastCacheDate)
      } else {
        endDate = moment(yesterday)
      }

      newState = {
        articles,
        activeArticle,
        startDate,
        endDate,
        minimumStartDate: startDate,
      }
    } else {
      activeArticle = this.state.activeArticle
    }

    let dataResult

    try {
      dataResult = await firebase.cloudFunction('getArticleDataFromCache', {
        articleId: activeArticle.id,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        userId: this.getFirstUserIdFromClient(this.state.activeClient),
      })
    } catch (error) {
      this.setState(
        {
          errorType: CoreError.NoData,
        },
        () => this.errorNotification({ populateArticles: false })
      )
      return
    }

    let data = null
    let errorType = CoreError.None
    try {
      data = consumeBatchData(dataResult)
    } catch (error) {
      console.error(error)
      if (error.message === 'NO_DATA') {
        errorType = CoreError.BadOutput
      }
    }

    this.setState(
      {
        ...newState,
        data,
        errorType,
        isLoading: false,
        firstLoadComplete: true,
      },
      () => {
        toast.dismiss()
      }
    )
  }

  render = () => {
    const {
      articles,
      activeArticle,
      clients,
      activeClient,
      startDate,
      endDate,
      minimumStartDate,
      isLoading,
      firstLoadComplete,
      data,
      errorType,
      isAdmin,
    } = this.state

    return (
      <Router>
        <Navigation
          articles={articles}
          activeArticle={activeArticle}
          changeArticle={this.changeArticle}
          clients={clients}
          activeClient={activeClient}
          changeClient={this.changeClient}
          startDate={startDate}
          endDate={endDate}
          minimumStartDate={minimumStartDate}
          consolidateDates={this.changeDates}
          isLoading={isLoading}
          wantsSignOut={this.wantsSignOut}
          isAdmin={isAdmin}
        />
        <Route path={ROUTES.LOG_IN} exact component={Login} />
        <Route
          path={ROUTES.DASHBOARD}
          render={props => (
            <Dashboard
              {...props}
              requestData={() => this.loadDashboardData({ populateArticles: true })}
              data={data}
              error={errorType}
              startDate={startDate}
              endDate={endDate}
              isLoading={isLoading}
              firstLoadComplete={firstLoadComplete}
              activeArticle={activeArticle}
              wantsSignOut={this.wantsSignOut}
            />
          )}
        />
        <ToastContainer autoClose={false} closeOnClick={false} closeButton={false} draggable={false} />
      </Router>
    )
  }
}

export default withAuthentication(App)
