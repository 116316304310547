import React from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import styled from 'styled-components'

import { withAuthentication, AuthUserContext } from '../../components/Session'
import { DASHBOARD } from '../../constants/routes'

import { PageContainer, Wrap } from '../../components/Layout'
import LoginForm from '../../components/LoginForm'
import { TabletAndMobile, Media } from '../../components/MediaQuery'

import logoSplash from '../../media/images/logo_horizontal_white.svg'
import { Colors } from '../../components/Color'

const ContainerHalf = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${Media.DesktopSmall`
    /* height: 50%; */
    width: 100%;
    flex: ${props => (props.logo ? '1' : '2')};
  `};
`

const LogoSplash = styled.img`
  height: 10%;
  margin-bottom: 8rem;
  ${Media.DesktopSmall`
    max-width: 100%;
    margin-bottom: 1rem;
  `};
`

class Login extends React.Component {
  render = () => {
    return (
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? (
            <Redirect to={DASHBOARD} />
          ) : (
            <TabletAndMobile>
              {isMobile => (
                <PageContainer fullheight color={Colors.Black.Main}>
                  <Helmet>
                    <title>Log In</title>
                  </Helmet>
                  <Wrap col={isMobile}>
                    <ContainerHalf logo>
                      <LogoSplash src={logoSplash} />
                    </ContainerHalf>
                    <ContainerHalf>
                      <LoginForm />
                    </ContainerHalf>
                  </Wrap>
                </PageContainer>
              )}
            </TabletAndMobile>
          )
        }
      </AuthUserContext.Consumer>
    )
  }
}

export default withAuthentication(Login)
