import React from 'react'
import { Helmet } from 'react-helmet'
import ReactDOM from 'react-dom'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'

import './react-dates-overrides.css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import Firebase, { FirebaseContext } from './components/Firebase'

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Helmet titleTemplate="TV2 Reporting - %s" />
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
