import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

import Color from './Color'

const Root = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background-color: ${Color.Primary.Main};
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
`

const Inner = styled.span`
  font-size: 0.8rem;
  color: white;
`

const TooltipIcon = ({ text }) => {
  return (
    <>
      <Root data-tip={text} data-multiline>
        <Inner>?</Inner>
      </Root>
      <ReactTooltip />
    </>
  )
}

export default TooltipIcon
