import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Autocomplete from 'react-autocomplete'

import Color from './Color'
import { Font } from './Text'

const MenuItem = styled.div`
  padding: 2rem;
  background-color: ${props => (props.isHighlighted ? '#eee' : '#fff')};
  font-family: ${Font.Heading};
  cursor: pointer;
`

const menuStyle = {
  zIndex: 1000,
  position: 'absolute',
  width: '18rem',
  minWidth: '14rem',
  marginTop: '1rem',
  marginLeft: '-1rem',
  maxHeight: '50vh',
  overflowY: 'scroll',
  boxShadow: '0 2px 6px rgba(0,0,0,.05), 0 0 0 1px rgba(0,0,0,.07)',
}

const wrapperStyle = isLoading => ({
  height: '2.5rem',
  padding: '0 0.2rem',
  marginRight: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '16rem',
  backgroundColor: isLoading ? '#f2f2f2' : 'transparent',
  overflowX: 'hidden',
})

const inputStyle = isLoading => ({
  width: '100%',
  padding: '0 0.2rem',
  border: 'none',
  fontFamily: Font.Heading,
  fontSize: '1.2rem',
  fontWeight: 600,
  fontStyle: isLoading ? 'italic' : 'normal',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  cursor: 'pointer',
  background: 'transparent',
  color: Color.Ghost.Main,
})

const MenuItemSubtitle = styled.div`
  color: ${Color.Black.Main};
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
`

class ArticlePicker extends Component {
  state = {
    value: '',
    menuOpen: false,
  }

  componentDidUpdate = ({ activeArticle: activeArticleStale }) => {
    const { activeArticle } = this.props

    if (activeArticle) {
      if (!activeArticleStale || activeArticle.id !== activeArticleStale.id) {
        const { title } = activeArticle
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          value: title,
        })
      }
    }
  }

  changeValue = (_, value) => {
    this.setState({ value })
  }

  selectValue = (value, article) => {
    const { changeArticle } = this.props
    this.input.blur()
    changeArticle(article)
    this.setState({ value })
  }

  getDisplayValueForInput = value => {
    const { articles } = this.props
    let output = ''
    articles.forEach(({ id, title }) => {
      if (id === value) {
        output = title
      }
    })

    return output
  }

  render = () => {
    const { value, menuOpen } = this.state
    const { articles, activeArticle, isLoading } = this.props

    const activeTitle = activeArticle ? activeArticle.title : ''

    let displayValue = ''
    if (!menuOpen) {
      if (value.length) {
        displayValue = value
      } else if (activeTitle.length) {
        displayValue = activeTitle
      }
    }

    return (
      <Autocomplete
        ref={element => {
          this.input = element
        }}
        getItemValue={item => item.title}
        shouldItemRender={(item, itemValue) => item.title.toLowerCase().indexOf(itemValue.toLowerCase()) > -1}
        items={articles}
        sortItems={({ liveDate: a }, { liveDate: b }) => moment(b).valueOf() - moment(a).valueOf()}
        renderItem={(item, isHighlighted) => (
          <MenuItem key={item.id} isHighlighted={isHighlighted}>
            <MenuItemSubtitle>{moment(item.liveDate).format('DD.MM.YY')}</MenuItemSubtitle>
            {item.title}
          </MenuItem>
        )}
        value={displayValue}
        onMenuVisibilityChange={isOpen => {
          if (isOpen) {
            this.setState({ value: '', menuOpen: true })
          } else if (value === '') {
            this.setState({ value: activeTitle, menuOpen: false })
          }
        }}
        onChange={this.changeValue}
        onSelect={this.selectValue}
        menuStyle={menuStyle}
        wrapperStyle={wrapperStyle(isLoading)}
        inputProps={{
          style: inputStyle(isLoading),
          className: 'autocompleteInput',
          placeholder: 'Select article',
        }}
      />
    )
  }
}

export default ArticlePicker
