import moment from 'moment'

const dateOptions = [
  {
    title: 'Yesterday',
    quantity: '0',
    unit: 'day',
  },
  {
    title: 'Past Week',
    quantity: '1',
    unit: 'week',
  },
  {
    title: 'Past Month',
    quantity: '1',
    unit: 'month',
  },
  {
    title: 'All Time',
    quantity: '100', // 1
    unit: 'year', // millenium
  },
]

// const startAndEndForDateOption = ({ quantity, unit }) => {
//   const endDate = moment().subtract(1, "day");
//   let startDate = moment(endDate).subtract(quantity, unit);
//   if (moment(startDate).isBefore(moment(minimumStartDate))) {
//     startDate = minimumStartDate;
//   }
//   return { startDate, endDate };
// };

const createCompatibleRange = ({ quantity, unit, minimumStartDate }) => {
  const endDate = moment().subtract(1, 'day')
  let startDate = moment(endDate).subtract(quantity, unit)
  if (moment(startDate).isBefore(moment(minimumStartDate))) {
    startDate = minimumStartDate
  }
  return { startDate, endDate }
}

export { dateOptions, createCompatibleRange }
