import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import moment from 'moment'

import Dropdown from './Dropdown'
import { withAuthorization } from './Session'
import * as SIZES from '../constants/sizes'
import Color from './Color'
import Icon, { IconName } from './Icon'
import DatePicker from './DatePicker'
import ArticlePicker from './ArticlePicker'
import ClientPicker from './ClientPicker'
import { ArticleModal, ClientModal, DateModal } from './Modal'
import LoadingBar from './LoadingBar'

import { Media, TabletAndMobile } from './MediaQuery'
import { TitleDetail, Font } from './Text'

const NavigationBlock = styled.div`
  background-color: #fff;
  overflow-y: visible;
  position: fixed;
  z-index: 100;
  width: 100vw;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.22);
  @media print {
    display: none;
  }
`

const NavigationHousing = styled.nav`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(${SIZES.NAV_HEIGHT} - 3px);
  ${Media.Tablet`
    height: 3rem;
  `};
`

const NavWrap = styled.div`
  max-width: ${SIZES.MAX_WIDTH};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  /* background-color: green; */
  padding-right: 2rem;
  ${Media.DesktopSmall`
    padding-left: 2rem;
  `};
`

const NavLogoContainer = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NavLogo = styled.img`
  height: auto;
  max-height: 2.5rem;
  max-width: 8rem;
  ${Media.DesktopSmall`
    max-height: 1.5rem;
    max-width: 4rem;
  `};
`

const NavItems = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 100%;
  ${Media.DesktopSmall`
    width: 100%;
    margin-left: 0;
  `}
`

const NavButton = styled.div`
  background-color: ${Color.Primary.Main};
  color: white;
  /* border-bottom: 3px solid ${Color.Ghost.Light}; */
  font-family: ${Font.Heading};
  font-weight: normal;
  font-size: 1.2rem;
  /* border-radius: calc(1.25rem + 3px); */
  padding: 0 1rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  height: 2rem;
`

const NavIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  z-index: 10;
  fill: ${Color.Primary.Main};
`

const MobileIcons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const MobileMenu = styled.div`
  height: 2rem;
  border: 2px solid ${Color.Monochrome.Main};
  fill: ${Color.Monochrome.Main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`

const MobileMenuIcon = styled.div`
  width: 2rem;
  height: 2rem;
`

const MobileTitle = styled(TitleDetail)`
  margin: 0;
  padding: 0;
  margin-right: 0.4rem;
  font-size: 0.8rem;
`

const getArticleName = article => (article ? `${article.title.substring(0, 5)}...` : 'Article')
const getClientName = client => (client ? `${client.name.substring(0, 5)}...` : 'Client')

class NavBar extends Component {
  state = {
    articleModalOpen: false,
    clientModalOpen: false,
    dateModalOpen: false,
  }

  changeModalFocus = ({ stateKey, shouldOpen }) => {
    document.body.style.overflow = shouldOpen ? 'hidden' : 'unset'
    this.setState({ [stateKey]: shouldOpen })
  }

  render = () => {
    const {
      articles,
      activeArticle,
      changeArticle,
      clients,
      activeClient,
      changeClient,
      location,
      isLoading,
      startDate,
      endDate,
      consolidateDates,
      minimumStartDate,
      wantsSignOut,
      isAdmin
    } = this.props

    const clientLogo = articles.length && articles[0].logoUrl

    const { articleModalOpen, clientModalOpen, dateModalOpen } = this.state

    const isLoginScreen = location.pathname === '/'

    return isLoginScreen ? null : (
      <Fragment>
        <NavigationBlock>
          <TabletAndMobile>
            {isSmall =>
              !isSmall && (
                <NavLogoContainer>
                  <NavLogo src={clientLogo} />
                </NavLogoContainer>
              )
            }
          </TabletAndMobile>
          <NavigationHousing>
            <NavWrap>
              <NavItems>
                <TabletAndMobile>
                  {isSmall =>
                    isSmall ? (
                      <Fragment>
                        <NavLogo src={clientLogo} />
                        <NavButton>Log Out</NavButton>
                      </Fragment>
                    ) : (
                      <Fragment>
                          {isAdmin && (
                          <>
                            <NavIcon>
                              <Icon name={IconName.PagePick} />
                            </NavIcon>
                              <ClientPicker
                                clients={clients}
                                activeClient={activeClient}
                                changeClient={changeClient}
                                isLoading={isLoading}
                              />
                          </>
                          )}
                        <NavIcon>
                          <Icon name={IconName.PagePick} />
                        </NavIcon>
                        <ArticlePicker
                          articles={articles}
                          activeArticle={activeArticle}
                          changeArticle={changeArticle}
                          isLoading={isLoading}
                        />
                        <NavIcon>
                          <Icon name={IconName.DatePick} />
                        </NavIcon>
                        <DatePicker
                          startDate={startDate}
                          endDate={endDate}
                          consolidateDates={consolidateDates}
                          isLoading={isLoading}
                          minimumStartDate={minimumStartDate}
                        />
                        <Dropdown
                          title="Log Out"
                          items={[
                            { name: 'Profile', action: null },
                            {
                              name: <span>Change&nbsp;Password</span>,
                              action: null,
                            },
                            {
                              name: 'Sign Out',
                              action: () => {
                                wantsSignOut()
                              },
                            },
                          ]}
                        />
                      </Fragment>
                    )
                  }
                </TabletAndMobile>
              </NavItems>
            </NavWrap>
          </NavigationHousing>
          <TabletAndMobile>
            <NavigationHousing>
              <NavWrap>
                <MobileIcons>
                  {isAdmin && (
                  <MobileMenu
                    onClick={() =>
                      this.changeModalFocus({
                        stateKey: 'clientModalOpen',
                        shouldOpen: true,
                      })
                    }>
                    <MobileMenuIcon>
                      <Icon name={IconName.PagePick} />
                    </MobileMenuIcon>
                     <MobileTitle>{getClientName(activeClient)}</MobileTitle>
                  </MobileMenu>
                  )}
                  <MobileMenu
                    onClick={() =>
                      this.changeModalFocus({
                        stateKey: 'articleModalOpen',
                        shouldOpen: true,
                      })
                    }>
                    <MobileMenuIcon>
                      <Icon name={IconName.PagePick} />
                    </MobileMenuIcon>
                    <MobileTitle>{getArticleName(activeArticle)}</MobileTitle>
                  </MobileMenu>
                  <MobileMenu
                    onClick={() =>
                      this.changeModalFocus({
                        stateKey: 'dateModalOpen',
                        shouldOpen: true,
                      })
                    }>
                    <MobileMenuIcon>
                      <Icon name={IconName.DatePick} />
                    </MobileMenuIcon>
                    <MobileTitle>
                      {moment(startDate).format('DD.MM.YY')}–{moment(endDate).format('DD.MM.YY')}
                    </MobileTitle>
                  </MobileMenu>
                </MobileIcons>
              </NavWrap>
            </NavigationHousing>
          </TabletAndMobile>
          <LoadingBar loading={isLoading} />
        </NavigationBlock>
        <ArticleModal
          showClose
          isOpen={articleModalOpen}
          // isLoading={isLoading}
          close={() =>
            this.changeModalFocus({
              stateKey: 'articleModalOpen',
              shouldOpen: false,
            })
          }
          articles={articles}
          activeArticle={activeArticle}
          changeArticle={article => {
            this.changeModalFocus({
              stateKey: 'articleModalOpen',
              shouldOpen: false,
            })
            changeArticle(article)
          }}
        />
           <ClientModal
            showClose
            isOpen={clientModalOpen}
            close={() =>
              this.changeModalFocus({
                stateKey: 'clientModalOpen',
                shouldOpen: false,
              })
            }
            clients={clients}
            activeClient={activeClient}
            changeClient={client => {
              this.changeModalFocus({
                stateKey: 'clientModalOpen',
                shouldOpen: false,
              })
              changeClient(client)
            }}
          />
        <DateModal
          showClose
          isOpen={dateModalOpen}
          isLoading={isLoading}
          close={() =>
            this.changeModalFocus({
              stateKey: 'dateModalOpen',
              shouldOpen: false,
            })
          }
          startDate={startDate}
          endDate={endDate}
          consolidateDates={dates => {
            this.changeModalFocus({
              stateKey: 'dateModalOpen',
              shouldOpen: false,
            })
            consolidateDates(dates)
          }}
          minimumStartDate={minimumStartDate}
        />
      </Fragment>
    )
  }
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(withRouter(NavBar))
