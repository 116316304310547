import React, { Component, Fragment } from 'react'
import styled, { keyframes } from 'styled-components'

import Color from './Color'
import { CoreError } from '../constants/errors'

const spinnerKeyframes = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`

const SpinnerHousing = styled.div`
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
`

const SpinnerDot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${Color.Primary.Light};
  animation: ${spinnerKeyframes} 1.2s linear infinite;
  ${props => `
    top: ${6 + Math.floor(props.n / 3) * 19}px;
    left: ${6 + (props.n % 3) * 19}px;
    animation-delay: ${props.n * Math.random() * -0.4}s;
  `}
`

const LoadingSpinner = () => (
  <SpinnerHousing>
    {[...new Array(9)].map((s, index) => (
      <SpinnerDot n={index} key={`spinner-dot-${Number(index)}`} />
    ))}
  </SpinnerHousing>
)

const LoadingContainer = styled.div`
  background-color: #fafafa;
  position: fixed;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${Color.Danger.Main};
  opacity: ${props => (props.fade ? '1' : '0')};
  transition: opacity 1s;
`

const ErrorTitle = styled.h1`
  margin: 0;
  margin-bottom: 2rem;
`

const ErrorDetail = styled.h3`
  margin: 0;
  margin-bottom: 1rem;
`

const ErrorRetryButton = styled.div`
  padding: 0.4rem 1.2rem;
  background-color: white;
  margin-top: 2rem;
  cursor: pointer;
  font-weight: bold;
  border: 2px solid red;
  font-size: 1.4rem;
`

const ErrorSecondary = styled.div`
  margin: 2rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ddd;
`

const ErrorLogOutLink = styled.span`
  text-decoration-style: dotted;
  text-decoration-line: underline;
  cursor: pointer;
  font-weight: bold;
`

class LoadingScreen extends Component {
  state = {
    show: this.props.reveal,
    fade: this.props.reveal,
  }

  componentDidMount = () => {
    this.manageOverflow()
  }

  componentDidUpdate = ({ reveal: staleReveal }) => {
    const { reveal } = this.props
    if (staleReveal !== reveal) {
      this.setState(
        {
          fade: reveal,
        },
        () =>
          setTimeout(() => {
            this.setState(
              {
                show: reveal,
              },
              this.manageOverflow
            )
          }, 1050)
      )
    }
  }

  manageOverflow = () => {
    const { reveal } = this.props
    document.body.style.overflow = reveal ? 'hidden' : 'unset'
  }

  disappear = () => {}

  render = () => {
    const { error, wantsReload, wantsSignOut } = this.props
    const { show, fade } = this.state
    return show ? (
      <LoadingContainer fade={fade}>
        {error === CoreError.None && (
          <Fragment>
            <LoadingSpinner />
            <h2 style={{ color: Color.Black.Dark, fontFamily: 'tv2sans-condensed-bold' }}>Loading Dashboard</h2>
          </Fragment>
        )}
        {error === CoreError.NoData && (
          <Fragment>
            <ErrorTitle>Error loading dashboard data</ErrorTitle>
            <ErrorDetail>There may be a problem with your internet connection.</ErrorDetail>
            <ErrorDetail>Please refresh the page or click below to retry:</ErrorDetail>
            <ErrorRetryButton onClick={wantsReload}>RELOAD</ErrorRetryButton>
            <ErrorSecondary>
              <ErrorDetail>If the problem persists, you can try logging out:</ErrorDetail>
              <ErrorLogOutLink onClick={wantsSignOut}>Log Out</ErrorLogOutLink>
            </ErrorSecondary>
          </Fragment>
        )}
      </LoadingContainer>
    ) : null
  }
}

export default LoadingScreen
