import React, { Component } from 'react'
import styled from 'styled-components'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

import { computeElementForDatapoint } from '../data-parsing'
import { DataPoint, ElementType, DeviceType } from '../../../constants/reporting'

import { Pane, PaneRow, PaneHeight } from '../../../components/Pane'
import { PageBreak } from '../../../components/Layout'

import Color from '../../../components/Color'
import { Media } from '../../../components/MediaQuery'

import Chart from '../../../components/Chart'
import ChartType from '../../../constants/charting'

const DropdownContainer = styled.div`
  position: fixed;
  top: 8rem;
  right: 2rem;
  max-width: 300px;
  z-index: 9999;
  border: 4px solid ${Color.Danger.Dark};

  display: flex;
  align-items: center;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.22);
  padding: 1rem;
  background-color: white;

  ${Media.DesktopSmall`
    padding: 0.2rem;
    width: calc(100% - 0.4rem);
    max-width: 100%;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid ${Color.Monochrome.Light};
    top: 8rem;
    right: 0;

    span {
      margin-left: 2rem;
    }
  `}
  ${Media.Tablet`
    top: 6rem;
  `}
`

const Legends = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

const LegendBox = styled.div`
  height: 1rem;
  width: 1rem;
  margin-right: 0.8rem;
  background-color: ${props => props.color};

  h4 {
    font-size: 0.8rem;
  }
`

const deviceTypeOptions = [
  { label: 'All Devices', value: DeviceType.All },
  { label: 'Mobile', value: DeviceType.Mobile },
  { label: 'Tablet', value: DeviceType.Tablet },
  { label: 'Desktop', value: DeviceType.Desktop },
]

class Videos extends Component {
  state = {
    deviceType: DeviceType.All,
  }

  render = () => {
    const { dataset, isLoading } = this.props
    const { deviceType } = this.state

    const videoPlaysTotal = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Figure,
      dataPoint: DataPoint.VideoPlays,
      deviceType,
    })
    const videoCompletionsTotal = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Figure,
      dataPoint: DataPoint.VideoCompletions,
      deviceType,
    })
    const videoFileCount = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Figure,
      dataPoint: DataPoint.VideoFileCount,
      deviceType,
    })

    const completionSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.VideoProgress,
      deviceType,
    })
    const comparisonSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.VideoCompletionComparison,
      deviceType,
    })

    return (
      <div>
        <PaneRow>
          <Pane
            fill
            position="first"
            elementType={ElementType.Figure}
            title="Video Plays"
            tooltip="A video play is tracked once a user click “Play” on an embedded video. Background videos are not included."
            value={videoPlaysTotal}
            isLoading={isLoading}
          />
          <Pane
            fill
            position="inner"
            elementType={ElementType.Figure}
            title="Video Completions"
            tooltip="video completion is tracked once a person has seen 100% of an embedded video. Background videos are not included."
            value={videoCompletionsTotal}
            isLoading={isLoading}
          />
          <Pane
            fill
            position="last"
            elementType={ElementType.Figure}
            title="Videos On Page"
            tooltip="Number of embedded videos on the page. Background videos not included."
            value={videoFileCount}
            isLoading={isLoading}
          />
        </PaneRow>
        <PageBreak />
        {dataset[DataPoint.VideoAnywaysSupported] ? (
          <>
            <DropdownContainer>
              <span>Filter by:&nbsp;</span>
              <Dropdown
                options={deviceTypeOptions}
                onChange={chosenValue => this.setState({ deviceType: chosenValue.value })}
                value={deviceTypeOptions[deviceTypeOptions.findIndex(({ value }) => value === deviceType)]}
                placeholder="All Devices"
              />
            </DropdownContainer>
            {completionSeries && (
              <PaneRow>
                <Pane
                  extraContent={
                    <Legends>
                      {completionSeries.points.map(({ name, color }) => (
                        <Legend>
                          <LegendBox color={color} />
                          <h4>{name}</h4>
                        </Legend>
                      ))}
                    </Legends>
                  }
                  height={PaneHeight.Medium}
                  isLoading={isLoading}
                  // position="first"
                  // flex={2}
                  elementType={ElementType.Series}
                  title="Video Progress"
                  tooltip="This overview explains how much of each video is viewed on average."
                  value={
                    <Chart
                      series={completionSeries.points}
                      type={ChartType.LinePercentages}
                      yDomain={completionSeries.yDomain}
                      xDomain={completionSeries.xDomain}
                    />
                  }
                />
              </PaneRow>
            )}

            {comparisonSeries && (
              <PaneRow>
                <Pane
                  extraContent={
                    <Legends>
                      {comparisonSeries.points.map(({ name, color }) => (
                        <Legend>
                          <LegendBox color={color} />
                          <h4>{name === 'finished' ? 'Completed Viewing' : 'Started Playback'}</h4>
                        </Legend>
                      ))}
                    </Legends>
                  }
                  height={PaneHeight.Medium}
                  isLoading={isLoading}
                  elementType={ElementType.Series}
                  title="Video % Completions"
                  value={
                    <Chart
                      series={comparisonSeries.points}
                      type={ChartType.BarStacked}
                      yDomain={comparisonSeries.yDomain}
                      xType={comparisonSeries.xType}
                      stackBy="y"
                    />
                  }
                />
              </PaneRow>
            )}
          </>
        ) : (
          <PaneRow>
            <Pane
              info
              elementType={ElementType.Figure}
              title="Video Tracking"
              value="Video tracking not set up for this article."
              height={PaneHeight.Tall}
            />
          </PaneRow>
        )}
      </div>
    )
  }
}

export default Videos
