import React, { Component } from 'react'
import styled from 'styled-components'
import Autocomplete from 'react-autocomplete'

import Color from './Color'
import { Font } from './Text'

const MenuItem = styled.div`
  padding: 2rem;
  background-color: ${props => (props.isHighlighted ? '#eee' : '#fff')};
  font-family: ${Font.Heading};
  cursor: pointer;
`

const menuStyle = {
  zIndex: 1000,
  position: 'absolute',
  // padding: "0 1rem",
  width: '18rem',
  minWidth: '14rem',
  marginTop: '1rem',
  marginLeft: '-1rem',
  maxHeight: '50vh',
  overflowY: 'scroll',
  boxShadow: '0 2px 6px rgba(0,0,0,.05), 0 0 0 1px rgba(0,0,0,.07)',
  // borderRadius: "2rem"
}

const wrapperStyle = isLoading => ({
  // borderBottom: `3px solid ${Color.Primary.Light}`,
  height: '2.5rem',
  padding: '0 0.2rem',
  marginRight: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // borderRadius: "2rem",
  width: '16rem',
  backgroundColor: isLoading ? '#f2f2f2' : 'transparent',
  overflowX: 'hidden',
})

const inputStyle = isLoading => ({
  width: '100%',
  padding: '0 0.2rem',
  border: 'none',
  fontFamily: Font.Heading,
  fontSize: '1.2rem',
  fontWeight: 600,
  fontStyle: isLoading ? 'italic' : 'normal',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  cursor: 'pointer',
  background: 'transparent',
  color: Color.Ghost.Main,
})

class ClientPicker extends Component {
  state = {
    value: '',
  }

  constructor(props) {
    super()

    this.state.value = props.activeClient.name
  }

  componentDidUpdate = ({ activeClient: activeClientStale }) => {
    const { activeClient } = this.props
    if (activeClient) {
      if (!activeClientStale || activeClient.id !== activeClientStale.id) {
        const { name } = activeClient
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          value: name,
        })
      }
    }
  }

  changeValue = (event, value) => {
    this.setState({ value })
  }

  selectValue = (value, client) => {
    const { changeClient } = this.props
    this.input.blur()
    changeClient(client)
    this.setState({ value })
  }

  getDisplayValueForInput = value => {
    const { clients } = this.props
    let output = ''
    clients.forEach(({ id, name }) => {
      if (id === value) {
        output = name
      }
    })
    return output
  }

  render = () => {
    const { value } = this.state
    const { clients, activeClient, isLoading } = this.props

    const activename = activeClient ? activeClient.name : ''

    return (
      <Autocomplete
        ref={element => {
          this.input = element
        }}
        getItemValue={(item) => item.name}
        items={clients}
        shouldItemRender={(item, itemValue) => item.name.toLowerCase().indexOf(itemValue.toLowerCase()) > -1}
        renderItem={(item, isHighlighted) => (
          <MenuItem key={item.name} isHighlighted={isHighlighted}>
            {item.name}
          </MenuItem>
        )}
        value={this.state.value}
        onMenuVisibilityChange={isOpen => {
          if (isOpen) {
            this.setState({ value: '' })
          } else if (value === '') {
            this.setState({ value: activename })
          }
        }}
        onChange={(e, val) => this.changeValue(e, val)}
        onSelect={this.selectValue}
        menuStyle={menuStyle}
        wrapperStyle={wrapperStyle(isLoading)}
        inputProps={{
          style: inputStyle(isLoading),
          className: 'autocompleteInput',
          placeholder: 'Select client',
        }}
      />
    )
  }
}

export default ClientPicker
