import React from 'react'
import moment from 'moment'
import momentDurationFormat from 'moment-duration-format'
import arraySort from 'array-sort'
import numeral from 'numeral'
import randomColor from 'randomcolor'

import {
  ReportPoint,
  DataPoint,
  // VideoProgress,
  ElementType,
  DeviceType,
} from '../../constants/reporting'

import Color from '../../components/Color'

const COLOR_BANK = [...Array(100)].map(() => randomColor())

const ORDINAL_NUMBERS = [
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
]

momentDurationFormat(moment)

const firstTotal = totals => Number(totals[0].values[0])
const numberedTotals = totals => totals[0].values.map(value => Number(value))

const toPercent = ({ t, p }) => Number((p / t) * 100).toFixed(2)

const roundUpToNearest = (number, unit) => Math.ceil(number / unit) * unit

const getEventRows = ({ rows, category }) => rows.filter(({ dimensions }) => dimensions[0] === category)

const deriveTotalFromRows = rows => {
  return rows.reduce((accumulator, { metrics }) => firstTotal(metrics) + accumulator, 0)
}

const getHighestY = series => {
  let highestY = 0
  series.forEach(({ data }) =>
    data.forEach(({ y }) => {
      if (y > highestY) {
        highestY = y
      }
    })
  )
  return roundUpToNearest(highestY, 1e3)
}

const computeSeriesForRawData = ({ startDate, endDate, data }) => {
  const dayDiff = moment(endDate).diff(startDate, 'days')
  const dayCodes = {}
  ;[...new Array(dayDiff)].forEach((day, index) => {
    const dayCode = moment(startDate)
      .add(index, 'days')
      .format('YYYYMMDD')
    dayCodes[dayCode] = 0
  })
  const { rows } = data.data.data.reports[0].data
  // Only get home URL for now
  // In future split into multiple series?
  // Or tally
  rows
    .filter(({ dimensions }) => dimensions[0] === '/')
    .forEach(({ dimensions, metrics }) => {
      const dateKey = dimensions[1]
      const value = Number(metrics[0].values[0])
      dayCodes[dateKey] = value
    })
  return Object.entries(dayCodes).map(([, value], index) => ({
    count: value,
    index,
  }))
}

const checkVideoEventString = ({ input, type }) => {
  switch (type) {
    case 'started':
      return input === 'Played video'
    case 'completed':
      return input === 'Finished' || input === '100%'
    case 'progress':
      return (
        input === 'Played video' ||
        input === 'Finished' ||
        input === '25%' ||
        input === '50%' ||
        input === '75%' ||
        input === '100%'
      )
    default:
      return null
  }
}

const cleanVideoName = name =>
  name
    .substring(name.lastIndexOf('/') + 1, name.indexOf('.mp4') === -1 ? name.length : name.indexOf('.mp4'))
    .replace(/_/gi, ' ')

const cleanForVideoEventString = ({ rows, type }) =>
  rows
    .map(({ dimensions: [eventCategory, event, videoName], metrics }) => ({
      dimensions: [eventCategory, event, cleanVideoName(videoName)],
      metrics,
    }))
    .filter(({ dimensions: [, input] }) =>
      checkVideoEventString({
        input,
        type,
      })
    )

const includesEitherOr = (string, comparisons) =>
  comparisons.map(comparison => string.includes(comparison)).includes(true)

const cleanNeueVideos = rows =>
  rows.filter(
    ({ dimensions: [, , label] }) =>
      !includesEitherOr(label, ['ambient', 'mobile', 'desktop', 'not set', '_', '-', 'Mobile', 'Desktop'])
  )

const getNeueVideoNames = rows =>
  rows
    .map(({ dimensions: [, , label] }) => label)
    .filter((value, index, inputArray) => inputArray.indexOf(value) === index)

const filterVideoEvents = (rows, { eventCategory, isUnique }) =>
  rows
    .filter(({ dimensions: [, category] }) => eventCategory(category))
    .map(({ dimensions, metrics }) => ({
      dimensions,
      metrics: [{ values: [metrics[0].values[isUnique ? 1 : 0]] }],
    }))

const getVideoFileCount = rows => getNeueVideoNames(rows).length

const getVideoSpecificTotal = ({ rows, deviceType }) => {
  const filteredTotals = rows.filter(({ dimensions }) => dimensions.includes(deviceType.toLowerCase()))
  if (filteredTotals.length) {
    return deriveTotalFromRows(filteredTotals)
  }
  return 0
}

const getVideoProgressSeries = ({ rows, deviceType }) =>
  [...new Set(rows.map(({ dimensions: [, , name] }) => name))]
    .sort((a, b) => ORDINAL_NUMBERS.indexOf(a.split(' ')[0]) - ORDINAL_NUMBERS.indexOf(b.split(' ')[0]))
    .map((videoName, videoIndex) => {
      const source = rows.filter(
        ({ dimensions: [, , name, device] }) =>
          name === videoName && (deviceType === DeviceType.All ? true : device === deviceType)
      )
      const data = [...Array(11)].map((_, i) => ({
        x: i * 10,
        y: 0,
      }))

      data[0].y = deriveTotalFromRows(source.filter(({ dimensions: [, type] }) => type === 'autoplay'))

      source
        .filter(({ dimensions: [, type] }) => type.includes('progress'))
        .forEach(
          ({
            dimensions: [, type],
            metrics: [
              {
                values: [value],
              },
            ],
          }) => {
            const progressNumber = Number(type.replace(/[^0-9]/g, ''))
            const index = progressNumber * 0.1
            data[index].y += Number(value)
          }
        )

      return {
        color: COLOR_BANK[videoIndex],
        name: videoName,
        data,
      }
    })

const getVideoCompletionSeries = ({ rows, deviceType }) => {
  const videoNames = [...new Set(rows.map(({ dimensions: [, , name] }) => name))].sort(
    (a, b) => ORDINAL_NUMBERS.indexOf(a.split(' ')[0]) - ORDINAL_NUMBERS.indexOf(b.split(' ')[0])
  )
  const series = ['finished', 'autoplay'].map(stateType => {
    const source = rows.filter(
      ({ dimensions: [, state, , device] }) =>
        state === stateType && (deviceType === DeviceType.All ? true : device === deviceType)
    )
    const data = videoNames.map(name => ({
      x: name,
      y: 0,
    }))
    source.forEach(
      ({
        dimensions: [, , name],
        metrics: [
          {
            values: [value],
          },
        ],
      }) => {
        const dataIndex = data.findIndex(({ x }) => x === name)
        data[dataIndex].y += Number(value)
      }
    )
    return {
      color: stateType === 'finished' ? Color.Azure.Main : Color.Azure.Fade,
      name: stateType,
      data,
    }
  })

  const normalised = series
    .map(group => {
      if (group.name === 'finished') {
        return group
      }

      const finishedGroup = series.find(({ name }) => name === 'finished')
      const newData = group.data.map(({ x, y }) => ({
        x,
        y: ((y - finishedGroup.data.find(({ x: finishedName }) => finishedName === x).y) / y) * 1e2,
      }))
      return {
        ...group,
        data: newData,
      }
    })
    .map((group, _, groups) => {
      if (group.name === 'autoplay') {
        return group
      }
      const autoplayGroup = groups.find(({ name }) => name === 'autoplay')
      const newData = group.data.map(({ x }) => ({
        x,
        y: 100 - autoplayGroup.data.find(({ x: autoplayName }) => autoplayName === x).y,
      }))
      return {
        ...group,
        data: newData,
      }
    })

  return normalised
}

const formatRowsForGroup = ({ total, rows, sort, reverse, nullCheck, includeDimensions }) => {
  if (nullCheck && (rows == null || rows.length === 0)) {
    return {
      total: 0,
      values: [],
    }
  }

  return {
    total,
    values: arraySort(
      rows.map(({ dimensions, metrics }) => {
        const count = firstTotal(metrics)
        return {
          label: dimensions.join(),
          count,
          percentage: toPercent({ p: count, t: total }),
          dimensions: includeDimensions && dimensions,
        }
      }),
      sort,
      reverse != null && { reverse }
    ),
  }
}

const consumeBatchData = data => {
  const { data: reports } = data
  const dataPoints = { ...DataPoint }
  // eslint-disable-next-line consistent-return
  reports.forEach(reportGroup => {
    const { name: reportPoint, data: groupData } = reportGroup

    // skip AD_MANAGER group
    if (reportPoint === 'AD_MANAGER') {
      dataPoints.AdManager = groupData

      return dataPoints
    }

    const { rows, totals } = groupData.data

    const singleTotal = firstTotal(totals)

    switch (reportPoint) {
      case ReportPoint.Times: {
        dataPoints.PageAverageTime = moment.duration(singleTotal, 'seconds').format()
        break
      }
      case ReportPoint.Views: {
        const [totalPageViews, totalUniquePageViews] = numberedTotals(totals)
        const splitRows = rows
          // this filtering removes items without a .dimensions property
          // this was seen when bringing down data for article that was re-released after
          // a period of no traffic (Elkjop smart hjem)
          .filter(item => Boolean(item.dimensions) === true)
          .map(({ dimensions, metrics }) => [
            metrics[0].values.map((metric, index) => ({
              dimensions: [moment(dimensions[0], 'YYYYMMDD').format()],
              metrics: [{ values: [metric] }],
              unique: index === 1,
            })),
          ])
          .flat(2)
        dataPoints.PageViews = formatRowsForGroup({
          total: totalPageViews,
          rows: splitRows.filter(({ unique }) => !unique),
          nullCheck: true,
        })
        dataPoints.PageViewsUnique = formatRowsForGroup({
          total: totalUniquePageViews,
          rows: splitRows.filter(({ unique }) => unique),
        })
        break
      }
      case ReportPoint.Events: {
        if (!rows || rows.length === 0) break

        const scrollRows = getEventRows({
          rows,
          category: 'ContentMarketingScroll',
        }).map(({ dimensions, metrics }) => ({
          dimensions: [dimensions[1]],
          metrics,
        }))

        const totalScrollEvents = deriveTotalFromRows(scrollRows)

        dataPoints.PageScrollDepth = formatRowsForGroup({
          total: totalScrollEvents,
          rows: scrollRows,
          sort: 'count',
          reverse: true,
          nullCheck: true,
        })

        const linkRows = getEventRows({
          rows,
          category: 'ContentMarketingLink',
        })

        const linkRowsNotUnique = linkRows.map(({ dimensions, metrics }) => ({
          metrics: [{ values: [metrics[0].values[0]] }],
          dimensions: dimensions.slice(1),
        }))
        const linkRowsNotUniqueTotal = deriveTotalFromRows(linkRowsNotUnique)

        const linkRowsUnique = linkRows.map(({ dimensions, metrics }) => ({
          metrics: [{ values: [metrics[0].values[1]] }],
          dimensions: dimensions.slice(1),
        }))
        const linkRowsUniqueTotal = deriveTotalFromRows(linkRowsUnique)

        dataPoints.LinkClicks = formatRowsForGroup({
          total: linkRowsNotUniqueTotal,
          rows: linkRowsNotUnique,
          includeDimensions: true,
        })
        dataPoints.LinkClicksUnique = formatRowsForGroup({
          total: linkRowsUniqueTotal,
          rows: linkRowsUnique,
          includeDimensions: true,
        })

        let videoRows = []
        const videoDataIsNeue = getEventRows({ rows, category: 'video' }).length

        if (videoDataIsNeue) {
          videoRows = getEventRows({ rows, category: 'video' })
          videoRows = cleanNeueVideos(videoRows)
          // const videoNames = getNeueVideoNames(videoRows)
          // console.log(`Video rows:\n\n${JSON.stringify(videoRows)}`)
          // console.log(`Video names:\n\n${JSON.stringify(videoNames)}`)
        } else {
          videoRows = getEventRows({
            rows,
            category: 'HTML5 Video',
          })
        }

        const videoPlayRows = videoDataIsNeue
          ? filterVideoEvents(videoRows, {
              eventCategory: category => category === 'autoplay',
              isUnique: true,
            })
          : cleanForVideoEventString({
              rows: videoRows,
              type: 'started',
            })

        const videoCompletionRows = videoDataIsNeue
          ? filterVideoEvents(videoRows, {
              eventCategory: category => category === 'finished',
              isUnique: true,
            })
          : cleanForVideoEventString({
              rows: videoRows,
              type: 'completed',
            })

        const videoProgressRows = videoDataIsNeue
          ? filterVideoEvents(videoRows, {
              eventCategory: category =>
                category.includes('progress') || category.includes('autoplay') || category.includes('finished'),
              isUnique: true,
            })
          : cleanForVideoEventString({
              rows: videoRows,
              type: 'progress',
            })

        const videoFileCount = videoDataIsNeue && getVideoFileCount(videoRows)

        // const totalLinkClinks = deriveTotalFromRows(linkRows);
        const totalVideoPlays = deriveTotalFromRows(videoPlayRows)
        const totalVideoCompletions = deriveTotalFromRows(videoCompletionRows)
        const totalVideoEvents = deriveTotalFromRows(videoProgressRows)

        dataPoints.VideoPlays = {
          ...formatRowsForGroup({
            total: totalVideoPlays,
            rows: videoPlayRows,
          }),
          raw: videoPlayRows,
        }
        dataPoints.VideoCompletions = {
          ...formatRowsForGroup({
            total: totalVideoCompletions,
            rows: videoCompletionRows,
          }),
          raw: videoCompletionRows,
        }
        dataPoints.VideoProgress = {
          ...formatRowsForGroup({
            total: totalVideoEvents,
            rows: videoProgressRows,
          }),
          raw: videoProgressRows,
        }
        dataPoints.VideoFileCount = {
          ...formatRowsForGroup({
            total: videoFileCount,
            rows: videoRows,
          }),
          raw: videoRows,
        }
        dataPoints.VideoCompletionComparison = {
          ...formatRowsForGroup({
            total: videoFileCount,
            rows: videoRows,
          }),
          raw: videoRows,
        }
        dataPoints.VideoAnywaysSupported = videoDataIsNeue
        break
      }

      case ReportPoint.UsersTotal: {
        dataPoints.UserTotal = singleTotal
        break
      }
      case ReportPoint.UsersAge: {
        const totalUsers = singleTotal
        dataPoints.UserAgeSplit = formatRowsForGroup({
          total: totalUsers,
          rows,
          nullCheck: true,
          sort: 'label',
        })
        break
      }
      case ReportPoint.UsersGender: {
        const totalUsers = singleTotal
        dataPoints.UserGenderSplit = formatRowsForGroup({
          total: totalUsers,
          rows,
          nullCheck: true,
        })
        break
      }
      case ReportPoint.UsersAgeGender: {
        const totalUsers = singleTotal
        dataPoints.UserAgeGenderSplit = formatRowsForGroup({
          total: totalUsers,
          rows,
          nullCheck: true,
          sort: 'label',
        })
        break
      }
      case ReportPoint.Locations: {
        const totalSessions = singleTotal
        dataPoints.UserCountry = formatRowsForGroup({
          total: totalSessions,
          rows,
        })
        break
      }
      case ReportPoint.Devices: {
        const totalSessions = singleTotal
        dataPoints.UserDevicesSplit = formatRowsForGroup({
          total: totalSessions,
          rows,
        })
        break
      }

      default:
        break
    }
  })
  return dataPoints
}

const thousands = number => numeral(number).format('0,0')

/* eslint-disable no-return-assign, no-param-reassign */
// From the one...
// The only...
// NINA SCHOLZ
// https://stackoverflow.com/questions/55258925/how-to-make-a-list-of-partial-sums-using-foreach/55259065#55259065
//
// const cumulativeSum = (sum => value => (sum += value))(0);
//
/* eslint-enable no-return-assign, no-param-reassign */

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

const standardiseGenderPoint = ({ count, percentage }, index) => ({
  x: count,
  y: index,
  p: Number(percentage) / 100,
})

const computeFigureForDatapoint = ({ set, dataPoint, deviceType }) => {
  const { total: setTotal, raw } = set
  switch (dataPoint) {
    case DataPoint.PageAverageTime:
      return set
    case DataPoint.UserTotal:
      return thousands(set)
    case DataPoint.PageViews:
    case DataPoint.PageViewsUnique:
      return thousands(setTotal)
    case DataPoint.VideoPlays:
    case DataPoint.VideoCompletions: {
      if (deviceType === DeviceType.All || deviceType == null) {
        return thousands(setTotal)
      }
      const filteredTotal = getVideoSpecificTotal({
        rows: raw,
        deviceType,
      })
      return thousands(filteredTotal) // TODO: New function to filter device type
    }
    case DataPoint.VideoFileCount:
      return setTotal
    default:
      break
  }

  return null
}
const computeProportionForDatapoint = ({ set, dataPoint }) => {
  const { values } = set
  switch (dataPoint) {
    case DataPoint.PageViews:
      break

    case DataPoint.UserGenderSplit:
    case DataPoint.UserDevicesSplit:
      return values.map(({ label, percentage }) => ({
        name: capitalize(label),
        value: Number(percentage).toFixed(1),
      }))

    default:
      break
  }

  return null
}
const computeSeriesForDatapoint = ({ set, dataPoint, deviceType }) => {
  const {
    // total: setTotal,
    values: points,
    raw,
  } = set

  if (points == null || points.length === 0) {
    return null
  }

  switch (dataPoint) {
    case DataPoint.VideoProgress: {
      if (raw.length === 0) {
        return null
      }

      const videoSeries = getVideoProgressSeries({ rows: raw, deviceType })
      const highestY = getHighestY(videoSeries)

      return {
        xDomain: [0, 100],
        yDomain: [0, highestY],
        points: videoSeries,
      }
    }
    case DataPoint.VideoCompletionComparison: {
      if (raw.length === 0) {
        return null
      }

      // const highestY = getHighestY(videoSeries);
      return {
        xType: 'ordinal',
        yDomain: [0, 100],
        points: getVideoCompletionSeries({ rows: raw, deviceType }),
      }
    }
    case DataPoint.PageScrollDepth: {
      if (points.length === 0) {
        return null
      }

      return {
        xDomain: [10, 100],
        yDomain: [0, points[0].count * 1.25],
        points: points.map(({ label, count, percentage }) => ({
          x: label,
          y: count,
          percentage,
        })),
      }
    }
    case DataPoint.PageViews: {
      if (points.length === 0) {
        return null
      }

      const pointsBase = points.slice().map(({ label, count }) => ({
        x: moment(label),
        y: count,
      }))

      let accumulator = 0

      return {
        points: pointsBase,
        pointsCumulative: pointsBase.map(({ y }, index) => {
          accumulator += y
          return {
            x: pointsBase[index].x,
            y: accumulator,
          }
        }),
      }
    }
    case DataPoint.LinkClicksUnique: {
      if (points.length === 0) {
        return null
      }

      const rows = points
        .map(({ dimensions, count }) => ({
          label: dimensions[1], // to be reduced to unique set of label strings
          url: dimensions[3],
          count: Number(count),
        }))
        .reduce((reducer, item) => {
          const index = reducer.findIndex(existingItem => existingItem.url === item.url)
          const updatedReducer = [...reducer]

          if (index >= 0) {
            // an item with URL already exists, concat the label and add clicks
            const existingItem = reducer[index]
            existingItem.label = existingItem.label.includes(item.label)
              ? existingItem.label
              : `${existingItem.label}, ${item.label}`
            existingItem.count += item.count

            updatedReducer[index] = existingItem
          } else {
            updatedReducer.push(item)
          }

          return updatedReducer
        }, [])
        .sort((a, b) => b.count - a.count)

      const totalClicks = rows.reduce((total, item) => total + item.count, 0)

      return {
        rows,
        columns: [
          {
            name: 'Link',
            wrap: false,
            sortable: false,
            cell: row => (
              <a rel="noopener noreferrer" target="_blank" href={row.url} style={{ display: 'block' }} title={row.url}>
                {row.label}
              </a>
            ),
            // button: true,
            ignoreRowClick: true,
          },
          {
            name: `${totalClicks} Unique Clicks`,
            selector: 'count',
            sortable: true,
            width: '150px',
            right: true,
          },
        ],
      }
    }
    case DataPoint.UserCountry: {
      if (points.length === 0) {
        return null
      }

      return {
        rows: points
          .map(({ label, count, percentage }, index) => ({
            id: index,
            country: label,
            count: Number(count),
            percentage: Number(percentage),
          }))
          .sort(({ count: a }, { count: b }) => b - a),
        columns: [
          {
            name: 'County',
            selector: 'country',
            sortable: true,
          },
          {
            name: 'Users',
            selector: 'count',
            sortable: true,
            format: row => numeral(row.count).format('0,0'),
          },
          {
            name: '% Total',
            selector: 'percentage',
            sortable: false,
            format: row => numeral(row.percentage / 100).format('0.00%'),
          },
        ],
      }
    }
    case DataPoint.UserAgeSplit: {
      if (points.length === 0) {
        return null
      }

      return {
        points: points.map(({ label, percentage }, index) => ({
          name: label,
          y: index,
          angle: Math.round(Number(percentage)),
        })),
      }
    }
    case DataPoint.UserAgeGenderSplit: {
      if (points.length === 0) {
        return null
      }

      const formattedPoints = points.map(({ label, count, percentage }) => {
        const parts = label.split(',')
        return {
          label: parts[0],
          gender: parts[1],
          count: Number(count),
          percentage,
        }
      })
      return {
        pointsFemale: formattedPoints
          .filter(({ gender }) => gender === 'female')
          .reverse()
          .map(standardiseGenderPoint),
        pointsMale: formattedPoints
          .filter(({ gender }) => gender === 'male')
          .reverse()
          .map(standardiseGenderPoint),
      }
    }
    default:
      break
  }

  return null
}

const computeElementForDatapoint = ({ dataset: set, elementType, dataPoint, deviceType }) => {
  if (!set) {
    console.error('set is undefined')
    console.error({
      elementType,
      dataPoint,
      deviceType,
    })

    return null
  }

  const subset = set[dataPoint]
  switch (elementType) {
    case ElementType.Figure:
      return computeFigureForDatapoint({ set: subset, dataPoint, deviceType })
    case ElementType.Proportion:
      return computeProportionForDatapoint({ set: subset, dataPoint })
    case ElementType.Series:
      return computeSeriesForDatapoint({ set: subset, dataPoint, deviceType })
    default:
      break
  }
  // console.error(
  //   `Error computing datapoint for element: ${elementType}, dataPoint: ${dataPoint}`
  // );
  return null
}

export { computeSeriesForRawData, consumeBatchData, computeElementForDatapoint, thousands }
