import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'

import Color from '../../../components/Color'

import { computeElementForDatapoint } from '../data-parsing'
import { DataPoint, ElementType } from '../../../constants/reporting'

import { Pane, PaneRow, PaneHeight } from '../../../components/Pane'
import { UserAgeGenderSplitRow } from '../../../components/FormattedPane'
import { PieChart, SplitBars } from '../../../components/Chart'
import { Mobile, TabletAndMobile } from '../../../components/MediaQuery'
import { PageBreak } from '../../../components/Layout'
import { TitleSmall } from '../../../components/Text'

const MixedChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const PieNumber = styled(TitleSmall)`
  margin: 1rem 0;
`

class Users extends Component {
  state = {
    crosshairs: [],
  }

  resetCrosshairs = () => this.setState({ crosshairs: [] })

  updateCrosshairs = x => this.setState({ crosshairs: [x] })

  render = () => {
    const { dataset, isLoading, print } = this.props
    const { crosshairs } = this.state

    const ageSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.UserAgeSplit,
    })
    const ageGenderSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.UserAgeGenderSplit,
    })
    const locationSeries = computeElementForDatapoint({
      dataset,
      elementType: ElementType.Series,
      dataPoint: DataPoint.UserCountry,
    })

    const agePiePane =
      ageSeries === null ? null : (
        <Pane
          height={PaneHeight.Medium}
          flex={1}
          elementType={ElementType.Series}
          title="Age"
          tooltip="Demographic data is gathered by Google, based on the people that are logged into a<br>Google profile (e.g. Google Chrome, Gmail or YouTube) while viewing the article."
          value={
            <MixedChartContainer>
              <PieChart
                data={ageSeries.points}
                updateCrosshairs={this.updateCrosshairs}
                resetCrosshairs={this.resetCrosshairs}
                crosshairs={crosshairs}
              />
              <PieNumber>
                {crosshairs.length ? `${ageSeries.points.slice().reverse()[crosshairs[0].y].angle}%` : '-'}
              </PieNumber>
            </MixedChartContainer>
          }
          isLoading={isLoading}
        />
      )

    const ageSplitPane =
      ageGenderSeries === null ? null : (
        <Pane
          height={PaneHeight.Medium}
          flex={1.5}
          elementType={ElementType.Series}
          title="Gender Breakdown"
          tooltip="Demographic data is gathered by Google, based on the people that are logged into a<br>Google profile (e.g. Google Chrome, Gmail or YouTube) while viewing the article."
          value={
            <MixedChartContainer>
              <SplitBars
                resetCrosshairs={this.resetCrosshairs}
                updateCrosshairs={this.updateCrosshairs}
                crosshairs={crosshairs}
                leftSeries={ageGenderSeries.pointsFemale}
                leftTitle="Female"
                rightSeries={ageGenderSeries.pointsMale}
                rightTitle="Male"
                title="Age"
                labels={['18 – 24', '25 – 34', '35 – 44', '45 – 54', '55 – 64', '65+']}
              />
            </MixedChartContainer>
          }
          isLoading={isLoading}
        />
      )

    return (
      <div>
        <UserAgeGenderSplitRow dataset={dataset} isLoading={isLoading} print={print} />
        {agePiePane && ageSplitPane && (
          <>
            <TabletAndMobile>
              {isMobile =>
                isMobile || print ? (
                  <Fragment>
                    {agePiePane && <PaneRow>{agePiePane}</PaneRow>}
                    {ageSplitPane && <PaneRow>{ageSplitPane}</PaneRow>}
                  </Fragment>
                ) : (
                  <PaneRow>
                    {agePiePane}
                    {ageSplitPane}
                  </PaneRow>
                )
              }
            </TabletAndMobile>
            <PageBreak />
          </>
        )}
        {locationSeries && (
          <PaneRow>
            <Pane
              // height={PaneHeight.Tall}
              flex={1}
              elementType={ElementType.Series}
              table
              title="Location"
              tooltip="Locations are based on IP addresses. In some cases, these locations might be wrong (e.g. some people using a VPN or public wifi).<br>“(Not set)” means we were not able to determine a physical location based on a users IP address."
              value={
                <Mobile>
                  {isMobile => (
                    <DataTable
                      columns={locationSeries.columns}
                      data={locationSeries.rows}
                      pagination={!isMobile && !print}
                      responsive={isMobile || print}
                      noHeader
                      customTheme={{
                        header: {
                          fontSize: '1.2rem',
                          fontColor: Color.Primary.Main,
                        },
                      }}
                    />
                  )}
                </Mobile>
              }
              isLoading={isLoading}
            />
          </PaneRow>
        )}
      </div>
    )
  }
}
export default Users
