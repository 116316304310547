import React, { Component } from 'react'
import styled from 'styled-components'

import Color from './Color'
import { Media } from './MediaQuery'
import { Font } from './Text'

const DropdownContainer = styled.div`
  position: relative;
  margin-left: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  ${Media.DesktopSmall`
    margin-left: auto;
  `};
`

const DropdownButton = styled.div`
  background-color: ${Color.Danger.Main};
  color: white;
  /* border-bottom: 3px solid ${Color.Ghost.Light}; */
  font-family: ${Font.Heading};
  font-size: 1.2rem;
  font-weight: 600;
  /* border-radius: calc(1.25rem + 3px); */
  padding: 0 1rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const DropdownPane = styled.ul`
  display: ${props => (props.open ? 'block' : 'none')};
  background-color: white;
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 100%;
  margin: 0;
  list-style: none;
  padding: 0 1rem;
`

const DropdownOption = styled.li`
  margin: 1rem 0;
  cursor: pointer;
`

export default class Dropdown extends Component {
  state = {
    open: false,
  }

  render = () => {
    const { open } = this.state
    const { title, items } = this.props
    return (
      <DropdownContainer
        // onMouseOver={() => this.setState({ open: true })}
        // onMouseLeave={() => this.setState({ open: false })}
        onClick={items[items.length - 1].action}>
        <DropdownButton>{title}</DropdownButton>
        <DropdownPane open={open}>
          {items.map(({ name, action }) => (
            <DropdownOption onClick={action} key={`dropdown-option-${name}`}>
              {name}
            </DropdownOption>
          ))}
        </DropdownPane>
      </DropdownContainer>
    )
  }
}
