import React, { Component } from 'react'
import styled from 'styled-components'

import Color from './Color'
import { TitleDetail } from './Text'
import { dateOptions, createCompatibleRange } from '../utils/dates'

const DatePickerMenu = styled.div`
  /* display: ${props => (props.show ? 'initial' : 'none')}; */
  position: absolute;
  top: 3rem;
  right: 0;
  width: 100%;
  height: 16rem;
  background-color: white;
  z-index: 200;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  /* padding: 1rem; */
`

const MenuOption = styled(TitleDetail)`
  margin: 0;
  padding: 1rem;
  width: calc(100% - 2rem);
  ${props => props.top && 'border-top: 1px solid #ccc'};
  &:hover {
    background-color: ${Color.Black.Fade};
  }
  user-select: none;
`

class DateDropdown extends Component {
  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = event => {
    if (this.dateDropdownNode.contains(event.target)) {
      return
    }
    this.handleClickOutside()
  }

  handleClickOutside = () => {
    const { wantsClose } = this.props
    wantsClose()
  }

  render = () => {
    const { wantsCustom, makeDateChange, minimumStartDate } = this.props
    return (
      <DatePickerMenu ref={node => (this.dateDropdownNode = node)}>
        {dateOptions.map(({ title, quantity, unit }) => (
          <MenuOption
            onClick={() =>
              makeDateChange(
                createCompatibleRange({
                  quantity,
                  unit,
                  minimumStartDate,
                })
              )
            }
            key={`date-picker-menu-option-${title}`}>
            {title}
          </MenuOption>
        ))}
        <MenuOption onClick={wantsCustom} top>
          Custom
        </MenuOption>
      </DatePickerMenu>
    )
  }
}

export default DateDropdown
