import FirebaseApp from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const config = {
  apiKey: 'AIzaSyAlnN339ygsurkL6858U9CNlpDejUqknW4',
  authDomain: 'tv2-ad-dashboard.firebaseapp.com',
  databaseURL: 'https://tv2-ad-dashboard.firebaseio.com',
  projectId: 'tv2-ad-dashboard',
  storageBucket: 'tv2-ad-dashboard.appspot.com',
  messagingSenderId: '484030929061',
  appId: '1:484030929061:web:8dde71f2149b7c9a',
}

export default class Firebase {
  constructor() {
    FirebaseApp.initializeApp(config)

    this.auth = FirebaseApp.auth()
    this.db = FirebaseApp.firestore()

    this.functions = FirebaseApp.app().functions('europe-west1')

    if (process.env.REACT_APP_LOCAL_FUNCTIONS_URL) {
      this.functions.useFunctionsEmulator(process.env.REACT_APP_LOCAL_FUNCTIONS_URL)
    }
    // Firebase Initilised
  }

  /** USERS * */
  // doCreateUserWithEmailAndPassword = (email, password) =>
  //   this.auth.createUserWithEmailAndPassword(email, password);

  // https://stackoverflow.com/a/35121112/1180964
  doSignInWithEmailAndPassword = (username, password) =>
    this.auth.signInWithEmailAndPassword(`${username}@annonse-admin.tv2.no`, password)

  doSignOut = () => this.auth.signOut()

  isAdmin = async () => this.auth.currentUser.getIdTokenResult().then(idToken => idToken.claims?.admin === true)

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  /** DATA * */
  getClient = () =>
    this.db
      .collection('clients')
      .doc('JwELvb6GJnwGMYQA6IfX')
      .get()

  /** FUNCTIONS * */
  cloudFunction = (name, data) => this.functions.httpsCallable(`${name}`)({ ...data })
}
