const ReportPoint = {
  Times: 'AVG_TIME_ON_PAGE',
  Views: 'PAGEVIEWS_DATEWISE',
  Events: 'EVENTS_ALL',
  UsersTotal: 'USERS_TOTAL',
  UsersAge: 'USERS_AGE',
  UsersGender: 'USERS_GENDER',
  UsersAgeGender: 'USERS_AGE_V_GENDER',
  Locations: 'LOCATIONS',
  Devices: 'DEVICES',
}

const DataPoint = {
  // Users
  UserTotal: 'UserTotal',
  UserGenderSplit: 'UserGenderSplit', // { male: %, female: % }
  UserDevicesSplit: 'UserDevicesSplit', // { desktop: %, tablet: %, phone: % }
  UserAgeSplit: 'UserAgeSplit', // [...{ ageRange: X, value: % }]
  UserAgeGenderSplit: 'UserAgeGenderSplit', // [...{ ageGroup: X, male: %, female: % }]
  UserCountry: 'UserCountry', // [...{ country: X, value: % }]
  // Pageviews
  PageViews: 'PageViews',
  PageViewsUnique: 'PageViewsUnique',
  // PageViewsTotal: 5, // Int — Total
  // PageViewsUniqueTotal: 6,
  PageAverageTime: 'PageAverageTime', // Moment Duration
  // PageTotalTime: 8, // Moment Duration
  // PageViewsDaily: 9, // [...{ date: moment, raw: X, unique: X }]
  PageScrollDepth: 'PageScrollDepth', //
  PageLinkClicks: 'PageLinkClicks', //
  // Videos
  VideoTotalViewTime: 'VideoTotalViewTime',
  // VideoCompletionCount: "VideoCompletionCount",
  // VideoCompletionPercentage: "VideoCompletionPercentage"
  VideoPlays: 'VideoPlays',
  VideoCompletions: 'VideoCompletions',
  VideoProgress: 'VideoProgress',
  VideoCompletionComparison: 'VideoCompletionComparison',
  VideoFileCount: 'VideoFileCount',
  VideoAnywaysSupported: 'VideoAnywaysSupported',
  LinkClicks: 'LinkClicks',
  LinkClicksUnique: 'LinkClicksUnique',
  // TODO: Add unique as separate names?
}

const VideoProgress = {
  Started: 'Play',
  StartMid: '25%',
  Half: '50%',
  EndMid: '75%',
  Completed: 'Finished',
}

const ElementType = {
  Figure: 0,
  Proportion: 1,
  Series: 2,
}

const DeviceType = {
  Mobile: 'mobile',
  Tablet: 'tablet',
  Desktop: 'desktop',
  All: 'All',
}

export { ReportPoint, DataPoint, VideoProgress, ElementType, DeviceType }
