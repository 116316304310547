import React from 'react'
import styled from 'styled-components'

import Color from '../Color'

const IconSvg = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
  width: 100%;
  height: 100%;
`

const IconRect = styled.rect`
  fill: none;
`

const IconPath = styled.path`
  fill-rule: nonzero;
  fill: ${props => (props.selected ? Color.Secondary.Main : Color.Monochrome.Dark)};
  ${props => props.fill && `fill: ${props.fill};`}
`

const IconName = {
  Home: 'Home',
  Users: 'Users',
  Views: 'Views',
  Video: 'Video',
  Scroll: 'Scroll',
  Export: 'Export',
  DatePick: 'DatePick',
  PagePick: 'PagePick',
  Male: 'Male',
  Female: 'Female',
  Mobile: 'Mobile',
  Desktop: 'Desktop',
  Tablet: 'Tablet',
  Link: 'Link',
  LogOut: 'LogOut',
}

const IconNamePath = {
  Home:
    'M250,153.97l80.04,72.036l0,125.022l-32.016,0l0,-96.048l-96.048,0l0,96.048l-32.016,0l0,-125.022l80.04,-72.036m0,-43.061l-160.08,144.071l48.024,0l0,128.064l96.048,0l0,-96.048l32.016,0l0,96.048l96.048,0l0,-128.064l48.024,0l-160.08,-144.071Z',
  Users:
    'M250,150.217c18.294,0 33.261,14.967 33.261,33.261c0,18.294 -14.967,33.261 -33.261,33.261c-18.294,0 -33.261,-14.967 -33.261,-33.261c0,-18.294 14.967,-33.261 33.261,-33.261m0,166.305c44.902,0 96.457,21.453 99.783,33.261l-199.566,0c3.825,-11.974 55.047,-33.261 99.783,-33.261m0,-199.566c-36.753,0 -66.522,29.769 -66.522,66.522c0,36.753 29.769,66.522 66.522,66.522c36.753,0 66.522,-29.769 66.522,-66.522c0,-36.753 -29.769,-66.522 -66.522,-66.522Zm0,166.305c-44.403,0 -133.044,22.285 -133.044,66.522l0,33.261l266.088,0l0,-33.261c0,-44.237 -88.641,-66.522 -133.044,-66.522Z',
  Views:
    'M242.026,312.543c13.759,0 26.581,-4.065 37.369,-10.945l38.152,38.151l22.202,-22.202l-38.151,-37.995c6.88,-10.945 10.945,-23.61 10.945,-37.37c0,-38.933 -31.428,-70.361 -70.361,-70.361c-38.933,0 -70.361,31.428 -70.361,70.361c0,38.933 31.428,70.361 70.205,70.361Zm0.156,-109.45c21.577,0 39.09,17.512 39.09,39.089c0,21.577 -17.513,39.09 -39.09,39.09c-21.577,0 -39.089,-17.513 -39.089,-39.09c0,-21.577 17.512,-39.089 39.089,-39.089Zm132.904,-78.179l-250.172,0c-17.2,0 -31.272,14.072 -31.272,31.271l0,187.63c0,17.199 14.072,31.271 31.272,31.271l250.172,0c17.2,0 31.272,-14.072 31.272,-31.271l0,-187.63c0,-17.199 -14.072,-31.271 -31.272,-31.271Zm0,218.901l-250.172,0l0,-187.63l250.172,0l0,187.63Z',
  Video:
    'M377.929,122.071l-255.858,0c-15.778,0 -28.429,12.651 -28.429,28.429l0,170.572c0,15.636 12.651,28.428 28.429,28.428l71.072,0l0,28.429l113.714,0l0,-28.429l71.072,0c15.636,0 28.287,-12.792 28.287,-28.428l0.142,-170.572c0,-15.778 -12.793,-28.429 -28.429,-28.429Zm0,199.001l-255.858,0l0,-170.572l255.858,0l0,170.572Zm-71.072,-85.286l-99.5,56.857l0,-113.715l99.5,56.858Z',
  Scroll:
    'M119.51,322.495l260.98,0l0,-28.998l-260.98,0l0,28.998Zm0,43.496l260.98,0l0,-14.498l-260.98,0l0,14.498Zm0,-101.492l260.98,0l0,-43.497l-260.98,0l0,43.497Zm0,-130.49l0,57.995l260.98,0l0,-57.995l-260.98,0Z',
  Export:
    'M353.479,239.771l0,103.478l-206.958,0l0,-103.478l-29.565,0l0,103.478c0,16.261 13.304,29.565 29.565,29.565l206.958,0c16.261,0 29.565,-13.304 29.565,-29.565l0,-103.478l-29.565,0Zm-88.696,9.904l38.287,-38.139l20.843,20.843l-73.913,73.913l-73.913,-73.913l20.843,-20.843l38.287,38.139l0,-142.948l29.566,0l0,142.948Z',
  DatePick:
    'M203.844,234.615l-30.77,0l0,30.77l30.77,0l0,-30.77Zm61.541,0l-30.77,0l0,30.77l30.77,0l0,-30.77Zm61.541,0l-30.77,0l0,30.77l30.77,0l0,-30.77Zm30.77,-107.696l-15.385,0l0,-30.771l-30.77,0l0,30.771l-123.082,0l0,-30.771l-30.77,0l0,30.771l-15.385,0c-17.078,0 -30.617,13.846 -30.617,30.77l-0.154,215.392c0,16.924 13.693,30.771 30.771,30.771l215.392,0c16.924,0 30.771,-13.847 30.771,-30.771l0,-215.392c0,-16.924 -13.847,-30.77 -30.771,-30.77Zm0,246.162l-215.392,0l0,-169.237l215.392,0l0,169.237Z',
  PagePick:
    'M373.081,366.774l0,-178.315l-92.311,-92.311l-123.081,0c-16.924,0 -30.617,13.847 -30.617,30.771l-0.153,246.162c0,16.924 13.692,30.771 30.616,30.771l184.776,0c6.923,0 13.078,-2.308 18.308,-6.154l-68.156,-68.157c-12.308,8.001 -26.77,12.77 -42.463,12.77c-42.463,0 -76.926,-34.463 -76.926,-76.926c0,-42.463 34.463,-76.926 76.926,-76.926c42.463,0 76.926,34.463 76.926,76.926c0,15.693 -4.769,30.155 -12.77,42.309l58.925,59.08Zm-169.237,-101.389c0,25.54 20.617,46.156 46.156,46.156c25.539,0 46.156,-20.616 46.156,-46.156c0,-25.539 -20.617,-46.155 -46.156,-46.155c-25.539,0 -46.156,20.616 -46.156,46.155Z',
  Male:
    'M186.744,208.237l126.512,0c19.67,0.001 35.639,15.97 35.639,35.639l0,94.102l-27.618,0l0,93.905l-142.554,0l0,-93.905l-27.618,0l0,-94.102c0,-19.669 15.969,-35.638 35.639,-35.639Zm63.256,-140.12c31.989,0 57.959,25.971 57.959,57.959c0,31.989 -25.97,57.959 -57.959,57.959c-31.989,0 -57.959,-25.97 -57.959,-57.959c0,-31.988 25.97,-57.959 57.959,-57.959Z',
  Female:
    'M193.032,208.237l113.936,0c19.67,0 35.638,15.969 35.638,35.639l0,38.443l-27.424,0c17.254,65.112 33.713,149.564 33.713,149.564l-197.79,0c0,0 16.459,-84.452 33.713,-149.564l-27.424,0l0,-38.443c0,-19.67 15.968,-35.639 35.638,-35.639Zm56.968,-140.12c31.989,0 57.959,25.971 57.959,57.959c0,31.989 -25.97,57.959 -57.959,57.959c-31.989,0 -57.959,-25.97 -57.959,-57.959c0,-31.988 25.97,-57.959 57.959,-57.959Z',
  Mobile:
    'M303.987,101.535l-107.974,0c-22.405,0 -40.49,18.086 -40.49,40.491l0,215.948c0,22.405 18.085,40.491 40.49,40.491l107.974,0c22.405,0 40.49,-18.086 40.49,-40.491l0,-215.948c0,-22.405 -18.085,-40.491 -40.49,-40.491Zm-26.993,269.936l-53.988,0l0,-13.497l53.988,0l0,13.497Zm43.864,-40.49l-141.716,0l0,-188.955l141.716,0l0,188.955Z',
  Desktop:
    'M383.618,101.535l-267.236,0c-16.331,0 -29.693,13.362 -29.693,29.693l0,178.158c0,16.331 13.362,29.693 29.693,29.693l103.925,0l-29.693,44.539l0,14.847l118.772,0l0,-14.847l-29.693,-44.539l103.925,0c16.331,0 29.693,-13.362 29.693,-29.693l0,-178.158c0,-16.331 -13.362,-29.693 -29.693,-29.693Zm0,178.158l-267.236,0l0,-148.465l267.236,0l0,148.465Z',
  Tablet:
    'M332.735,101.535l-165.47,0c-22.89,0 -41.367,16.579 -41.367,37.117l0,222.696c0,20.538 18.477,37.117 41.367,37.117l165.47,0c22.89,0 41.367,-16.579 41.367,-37.117l0,-222.696c0,-20.538 -18.477,-37.117 -41.367,-37.117Zm-55.157,272.185l-55.156,0l0,-12.372l55.156,0l0,12.372Zm72.393,-37.116l-199.942,0l0,-197.952l199.942,0l0,197.952Z',
  Link:
    'M118.762,250c0,-27.706 22.521,-50.227 50.227,-50.227l64.809,0l0,-30.784l-64.809,0c-44.718,0 -81.011,36.293 -81.011,81.011c0,44.718 36.293,81.011 81.011,81.011l64.809,0l0,-30.784l-64.809,0c-27.706,0 -50.227,-22.521 -50.227,-50.227Zm66.429,16.202l129.618,0l0,-32.404l-129.618,0l0,32.404Zm145.82,-97.213l-64.809,0l0,30.784l64.809,0c27.706,0 50.227,22.521 50.227,50.227c0,27.706 -22.521,50.227 -50.227,50.227l-64.809,0l0,30.784l64.809,0c44.718,0 81.011,-36.293 81.011,-81.011c0,-44.718 -36.293,-81.011 -81.011,-81.011Z',
  LogOut:
    'M250,94.594c-85.784,0 -155.406,69.622 -155.406,155.406c0,85.784 69.622,155.406 155.406,155.406c85.784,0 155.406,-69.622 155.406,-155.406c0,-85.784 -69.622,-155.406 -155.406,-155.406Zm-124.325,155.406c0,-68.689 55.636,-124.325 124.325,-124.325c28.75,0 55.169,9.791 76.149,26.264l-174.21,174.21c-16.473,-20.98 -26.264,-47.399 -26.264,-76.149Zm124.325,124.325c-28.75,0 -55.169,-9.791 -76.149,-26.264l174.21,-174.21c16.473,20.98 26.264,47.399 26.264,76.149c0,68.689 -55.636,124.325 -124.325,124.325Z',
}

export default ({ name, selected, fill }) => (
  <IconSvg width="100%" height="100%" viewBox="0 0 500 500">
    <IconRect id="export" x="0" y="0" width="500" height="500" />
    <IconPath d={IconNamePath[name]} selected={selected} fill={fill} />
  </IconSvg>
)

export { IconName }
