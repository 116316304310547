import chroma from 'chroma-js'

const Colors = {
  Red: {
    Dark: '#6F03FF',
    Main: '#6F03FF',
    Light: '#B2AEFF',
    Fade: '#B2AEFF',
    Highlight: '#B2AEFF',
  },
  Lilla: {
    Main: '#6F03FF',
    Light: '#B2AEFF',
  },
  Songs: {
    Main: '#FFBF94',
    Light: '#FFDDCA',
  },
  Blue: {
    Main: '#0264d1',
    Light: '#3abff7',
    Fade: '#a3e0fd',
    Highlight: '#D9F2FF',
  },
  Green: {
    Main: '#D6FDE2',
    Light: '#D6FDE2',
    Fade: '#D6FDE2',
  },
  Orange: {
    Main: '#FF6014',
    Light: '#FF7F41',
    Fade: '#FFA071',
  },
  Yellow: {
    Main: '#ffc905',
    Light: '#ffe866',
    Fade: '#fff2bd',
  },
  Gray: {
    Dark: '#BEBEBE',
    Main: '#DEDEDE',
    Light: '#ECECEC',
    Fade: '#ECECEC',
    Highlight: '#FFFFFF',
  },
  Black: {
    Dark: '#100118',
    Main: '#100118',
    Light: '#6C5C5B',
    Fade: '#C6B5B4',
    Highlight: '#F3E9E9',
  },
}

const Palette = {
  Primary: Colors.Lilla,
  Secondary: Colors.Songs,
  Danger: Colors.Red,
  Warning: Colors.Yellow,
  Monochrome: Colors.Gray,
  Black: Colors.Black,
  Ghost: Colors.Black,
  Azure: Colors.Blue,
}

const createPaletteBetween = ({ start, end, count }) => {
  const colorScale = chroma.scale([start, end])
  return [...new Array(count)].map((c, index) => colorScale((index + 1) / count))
}

export default Palette
export { createPaletteBetween, Colors }
