import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import styled from 'styled-components'

import Color from './Color'
import DateDropdown from './DateDropdown'

const CalendarInfo = styled.div`
  width: calc(100% - 2rem);
  border-top: solid 1px #e4e7e7;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.8rem 1rem;
`

const CalendarButton = styled.div`
  padding: 0.4rem 0.8rem;
  font-weight: 600;
  background-color: ${props => (props.primary ? Color.Primary.Main : 'transparent')};
  color: ${props => (props.primary ? 'white' : Color.Ghost.Light)};
  margin-left: 0.4rem;
  border-radius: 2rem;
  transition: background-color 0.4s;
  cursor: pointer;
  &:hover {
    ${props =>
      props.primary ? `background-color: ${Color.Primary.Light};` : `background-color: ${Color.Monochrome.Fade};`}
  }
`

const CalendarInfoPane = ({ apply, cancel }) => (
  <CalendarInfo>
    <CalendarButton onClick={cancel}>Cancel</CalendarButton>
    <CalendarButton primary onClick={apply}>
      Apply
    </CalendarButton>
  </CalendarInfo>
)

const DatePickerHousing = styled.div`
  height: 2.5rem;
  position: relative;
  cursor: pointer;
`

const DatePickerOverlay = styled.div`
  position: absolute;
  /* background-color: red;
  opacity: 0.4; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

class DatePicker extends Component {
  state = {
    /* eslint-disable-line react/destructuring-assignment */
    displayStartDate: this.props.startDate,
    displayEndDate: this.props.endDate,
    /* eslint-enable-line react/destructuring-assignment */
    focusedInput: null,
    showDropdown: false,
    showCustom: false,
  }

  componentDidUpdate = ({ startDate: startDateStale, endDate: endDateStale }) => {
    const { startDate, endDate } = this.props
    if (!moment(startDateStale).isSame(moment(startDate)) || !moment(endDateStale).isSame(moment(endDate))) {
      this.setState({ displayStartDate: startDate, displayEndDate: endDate })
    }
  }

  changeFocus = focusedInput => {
    if (!focusedInput) {
      this.reset()
      return
    }

    const { displayEndDate } = this.state
    if (displayEndDate) {
      this.setState({ focusedInput, showCustom: true })
    } else {
      this.setState({ focusedInput: 'endDate', showCustom: true })
    }
  }

  changeDates = ({ startDate, endDate }) => {
    const { focusedInput } = this.state
    const newState = {
      displayStartDate: startDate,
      displayEndDate: endDate,
    }
    if (focusedInput === 'endDate') {
      newState.focusedInput = 'startDate'
    }
    if (focusedInput === 'startDate') {
      newState.focusedInput = 'endDate'
    }
    this.setState({ ...newState })
  }

  consolidate = () => {
    const { displayStartDate, displayEndDate } = this.state
    const { consolidateDates } = this.props

    this.setState({
      focusedInput: null,
      showCustom: false,
      showDropdown: false,
    })

    consolidateDates({
      startDate: displayStartDate,
      endDate: displayEndDate,
    })
  }

  reset = () => {
    const { startDate, endDate } = this.props

    this.setState({
      displayStartDate: startDate,
      displayEndDate: endDate,
      focusedInput: null,
      showCustom: false,
      showDropdown: false,
    })
  }

  rangeCheck = dateToCheck => {
    const { minimumStartDate } = this.props
    const endCheck = dateToCheck.isAfter(
      moment()
        .subtract(1, 'day')
        .endOf('day')
    )
    const startCheck = dateToCheck.isBefore(moment(minimumStartDate))

    return startCheck || endCheck
  }

  closeDropdown = () => this.setState({ showDropdown: false })

  render = () => {
    const { displayStartDate, displayEndDate, focusedInput, showDropdown, showCustom } = this.state
    const { isLoading, mobile, minimumStartDate, consolidateDates } = this.props
    return (
      <DatePickerHousing>
        <DateRangePicker
          displayFormat="DD.MM.YY"
          renderCalendarInfo={() => <CalendarInfoPane apply={this.consolidate} cancel={this.reset} />}
          customArrowIcon={<div>&ndash;</div>}
          hideKeyboardShortcutsPanel
          startDate={displayStartDate}
          startDateId="your_unique_start_date_id"
          endDate={displayEndDate}
          endDateId="your_unique_end_date_id"
          numberOfMonths={1}
          minimumNights={0}
          keepOpenOnDateSelect
          onDatesChange={this.changeDates}
          focusedInput={focusedInput}
          onFocusChange={this.changeFocus}
          isOutsideRange={this.rangeCheck}
          disabled={isLoading}
          withFullScreenPortal={mobile}
        />
        {/* Media Query */}
        {!mobile && !showCustom && <DatePickerOverlay onClick={() => this.setState({ showDropdown: true })} />}

        {!mobile && showDropdown && !showCustom && (
          <DateDropdown
            wantsClose={this.closeDropdown}
            wantsCustom={() => this.changeFocus('startDate')}
            minimumStartDate={minimumStartDate}
            makeDateChange={dates => {
              this.closeDropdown()
              consolidateDates(dates)
            }}
          />
        )}
      </DatePickerHousing>
    )
  }
}

export default DatePicker
